import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastr } from "react-redux-toastr";
import { FormattedMessage, injectIntl } from "react-intl";

import { OldInput } from "@components/OldInput";
import { Button, EButtonType } from "@components/OldButton";
import {
  EMPTY_VALIDATION_MESSAGE_CONFIG,
  EValidationMessageType,
} from "@components/ValidationMessage";

import "./Login.scss";
import * as actions from "../../actions";
import { TRootState } from "../../redux/types";
import * as userActions from "../../actions/user";
import logo1x from "../../assets/imgs/logo-220w.jpg";
import logo2x from "../../assets/imgs/logo-600w.jpg";
import { defined } from "../../utils/variableEvaluation";
import { getParameterByName } from "../../utils/toSearchParams";
import { ReactComponent as IconEye } from "../../assets/icons/eye.svg";
import * as loginActions from "../../shared/state/AuthenticateActions";
import { testsFailed, isInputValid } from "../../utils/validationTests";
import { EValidationTestType, VALIDATION_MESSAGE } from "../../utils/validationConfigs";

interface State {
  login: string;
  pin: string;
  password: string;
  newPassword: string;
  passwordType: string;
  oneTimeToken: string;
  rememberMe: boolean;
  passwordResetStep: string;
  passwordResetToken: string;
  showValidations: boolean;
}

interface Props {
  actions: any;
  loginActions: any;
  userActions: any;
  location: any;
  history: any;
  isLoading: boolean;
  setLoading: any;
  authentication: any;
  intl: any;
}

const mapStateToProps = ({ AppReducer }: TRootState) => ({
  ...AppReducer,
  isLoading: AppReducer.loading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions as any, dispatch),
  loginActions: bindActionCreators(loginActions as any, dispatch),
  userActions: bindActionCreators(userActions as any, dispatch),
  setLoading: val => dispatch(actions.setLoading(val)),
});

class Login extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    document.body.classList.add("login");

    let user = "";
    let passwordResetStep = "0";
    let oneTimeToken = "";

    if (this.props.location.search && this.props.location.search.length > 0) {
      oneTimeToken = getParameterByName("oneTimeToken", this.props.location.search);
      // modalContentId = getParameterByName('edit', this.props.location.search);
      user = getParameterByName("user", this.props.location.search);
      passwordResetStep = getParameterByName("password-reset", this.props.location.search);
      // tabId = Number(getParameterByName('tab', this.props.location.search));
    }

    this.state = {
      passwordResetToken: "",
      login: user,
      pin: "",
      password: "",
      oneTimeToken: oneTimeToken,
      showValidations: false,
      newPassword: "",
      passwordType: "password",
      rememberMe: false,
      passwordResetStep: passwordResetStep || "0",
    };
  }

  private inputs = [
    {
      name: "login",
      validationTests: [EValidationTestType.hasValue],
    },
    {
      name: "password",
      validationTests: [EValidationTestType.hasValue],
    },
  ];

  static getDerivedStateFromProps(props) {
    if (props.location.search === "") {
      return {
        passwordResetStep: "0",
      };
    } else {
      if (getParameterByName("2fa", props.location.search) === "1") {
        return {
          passwordResetStep: "3",
        };
      } else {
        return {
          // login: getParameterByName('user', props.location.search),
          passwordResetStep: getParameterByName("password-reset", props.location.search),
          passwordResetToken: getParameterByName("token", props.location.search),
        };
      }
    }
  }

  componentDidMount() {
    // Check if it have a oneTimeToken
    if (this.state.oneTimeToken) {
      this.props.loginActions({
        oneTimeToken: this.state.oneTimeToken,
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("login");
  }

  validationMessage = (inputName, validationTests) => {
    let testsFailedArr: EValidationTestType[] = [];

    testsFailedArr = testsFailed(validationTests, this.state[inputName]);

    if (testsFailedArr.length > 0) {
      return VALIDATION_MESSAGE[testsFailedArr[0]];
    } else {
      return EMPTY_VALIDATION_MESSAGE_CONFIG;
    }
  };

  validationMessageConfig = (inputName, defaultValidationMessageConfig, validationTests) => {
    if (this.state.showValidations) {
      if (defined(validationTests)) {
        return this.validationMessage(inputName, validationTests);
      } else {
        return EMPTY_VALIDATION_MESSAGE_CONFIG;
      }
    } else {
      return defaultValidationMessageConfig || EMPTY_VALIDATION_MESSAGE_CONFIG;
    }
  };

  checkFormValidity(inputValues, inputs) {
    let isValid = null;

    isValid = inputs.every(input => {
      return isInputValid(inputValues, input.validationTests, input.name);
    });

    if (isValid) {
      this.setState({ showValidations: false });
    } else {
      this.setState({ showValidations: true });
    }
    return isValid;
  }

  async login() {
    if (this.state.passwordResetStep === "3") {
      try {
        const res = await this.props.loginActions({
          pin: this.state.pin,
          token: this.props.authentication.token,
          step: "2fa",
        });
        if (res === "MfaFailed") {
          toastr.error("2fa", res);
        }
      } catch (error) {
        console.error("object error", error);
      }
    } else {
      if (this.checkFormValidity(this.state, this.inputs)) {
        this.setState({ showValidations: false });
        await this.props.loginActions.login({
          login: this.state.login,
          password: this.state.password,
          rememberMe: this.state.rememberMe,
        });
      } else {
        this.setState({ showValidations: true });
      }
    }
  }

  sendPasswordResetRequest() {
    // let resp =  await this.props.userActions.passwordForgotInit(this.state.login);
    this.props.userActions.passwordForgotInit(this.state.login).then(res => {
      if (res === "OK") {
        this.props.history.push({
          // pathname: `/login/${this.state.currentTab}`,
          search: "?" + new URLSearchParams({ "password-reset": "4" }).toString(),
        });
      }
    });
  }

  setNewPassword() {
    // let resp =  await this.props.userActions.passwordForgotInit(this.state.login);
    this.props.userActions
      .passwordReset(this.state.newPassword, this.state.passwordResetToken)
      .then(res => {
        if (res === "OK") {
          toastr.success(
            this.props.intl.formatMessage({
              id: "app.newpassword",
              defaultMessage: "New password",
            }),
            this.props.intl.formatMessage({
              id: "app.passwordwaschanged",
              defaultMessage: "Your password was changed",
            })
          );
          setTimeout(() => {
            this.props.history.push({
              pathname: `/login`,
              search: "",
            });
          }, 2500);
        }
      });
  }

  changePasswordInputType() {
    if (this.state.passwordType === "text") {
      this.setState({ passwordType: "password" });
    } else {
      this.setState({ passwordType: "text" });
    }
  }

  resetPassword() {
    this.props.history.push({
      // pathname: `/login/${this.state.currentTab}`,
      search:
        "?" + new URLSearchParams({ "password-reset": "1", user: this.state.login }).toString(),
    });
  }

  renderLogin() {
    return (
      <div className="card mx-auto text-right">
        <img
          alt="logo"
          src={logo1x}
          srcSet={logo1x + " 1x," + logo2x + " 2x"}
          className="login-logo"
        />
        <div className="login-title">Administration</div>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div data-cy="usernameLogin">
            <OldInput
              value={this.state.login}
              name={this.inputs[0].name}
              onChange={(name, value) => this.setState({ login: value })}
              type="text"
              placeholder={this.props.intl.formatMessage({
                id: "app.username",
                defaultMessage: "Username",
              })}
              inputWrapperClass="text-input"
              inputWrapperStyle={{ marginBottom: "32px" }}
              validationMessageConfig={this.validationMessageConfig(
                this.inputs[0].name,
                {},
                this.inputs[0].validationTests
              )}
            >
              <FormattedMessage id="app.username" defaultMessage="Username" />
              {/* { */}
              {/*    this.state.login && */}
              {/*    this.state.login.length > 3 && <IconCheckmark style={{ */}
              {/*                position: 'absolute', */}
              {/*                right: '0', */}
              {/*                bottom: '-46px' */}
              {/*            }} className="iconWrapper"/> */}
              {/* } */}
            </OldInput>
          </div>
          <div data-cy="passwordLogin">
            <OldInput
              value={this.state.password}
              name={this.inputs[1].name}
              onChange={(name, value) => this.setState({ password: value })}
              validationMessageConfig={this.validationMessageConfig(
                this.inputs[1].name,
                {},
                this.inputs[1].validationTests
              )}
              type={this.state.passwordType}
              placeholder={this.props.intl.formatMessage({
                id: "app.password",
                defaultMessage: "Password",
              })}
              inputWrapperClass="text-input"
            >
              <FormattedMessage id="app.password" defaultMessage="Password" />
              <IconEye
                onClick={() => this.changePasswordInputType()}
                className={classNames("icon-show-password", {
                  dark: this.state.passwordType === "text",
                })}
              />
            </OldInput>
          </div>
          <div className="login-subcontent">
            {/* <Input checked={this.state.rememberMe} checkboxLabel="Login merken" onChange={(name, value) => this.setState({rememberMe: value})} type='checkbox' inputWrapperClass="mb-0" inputWrapperStyle={{ */}
            {/*        display: 'flex', */}
            {/*        width: 'auto', */}
            {/*        alignItems: 'center' */}
            {/*    }}></Input> */}
            <Button
              className="primary small mt-4 ml-auto buttonLink"
              onClick={() => {
                this.resetPassword();
              }}
              buttonType={EButtonType.ButtonText}
            >
              <FormattedMessage id="app.forgotpassword" defaultMessage="Forgot Password" />
            </Button>
          </div>
          <Button
            className="primary full-width mt-5"
            submit={true}
            onClick={() => {
              this.login();
            }}
            buttonType={EButtonType.Button}
            // isLoading={this.props.isLoading}
            // isDisabled={this.props.isLoading}
          >
            <FormattedMessage id="app.signin" defaultMessage="Sign In" />
          </Button>
        </form>
      </div>
    );
  }

  render2fa() {
    return (
      <div className="card mx-auto text-right">
        <img
          alt="logo"
          src={logo1x}
          srcSet={logo1x + " 1x," + logo2x + " 2x"}
          className="login-logo"
        />
        <div className="login-title">
          <FormattedMessage
            id="app.twofactorauthentication"
            defaultMessage="Two Factor Authentication"
          />
        </div>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <OldInput
            name="username"
            value={this.state.pin}
            onChange={(name, value) => this.setState({ pin: value })}
            validationMessageConfig={null}
            type="text"
            inputWrapperClass="text-input"
          >
            <FormattedMessage id="app.pin" defaultMessage="Code" />
            {/* { */}
            {/*    this.state.pin && */}
            {/*    this.state.pin.length > 3 && <IconCheckmark style={{ */}
            {/*                position: 'absolute', */}
            {/*                right: '0', */}
            {/*                bottom: '-46px' */}
            {/*            }} className="iconWrapper"/> */}
            {/* } */}
          </OldInput>
          <Button
            className="primary full-width mt-5"
            submit={true}
            onClick={() => {
              this.login();
            }}
            buttonType={EButtonType.Button}
            // isLoading={this.props.isLoading}
            // isDisabled={this.props.isLoading}
          >
            <FormattedMessage id="app.signin" defaultMessage="Sign In" />
          </Button>
        </form>
      </div>
    );
  }

  renderStep1() {
    return (
      <div className="card mx-auto text-right">
        <img
          alt="logo"
          src={logo1x}
          srcSet={logo1x + " 1x," + logo2x + " 2x"}
          className="login-logo"
        />
        <div className="login-title">
          <FormattedMessage id="app.resetpassword" defaultMessage="Reset Password" />
        </div>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <OldInput
            name="username"
            value={this.state.login}
            onChange={(name, value) => this.setState({ login: value })}
            validationMessageConfig={this.validationMessageConfig("login", {}, [
              EValidationTestType.hasValue,
            ])}
            type="text"
            placeholder={this.props.intl.formatMessage({
              id: "app.username",
              defaultMessage: "Username",
            })}
            inputWrapperClass="text-input"
          >
            <FormattedMessage id="app.username" defaultMessage="Username" />
            {/* { */}
            {/*    this.state.login && */}
            {/*    this.state.login.length > 3 && <IconCheckmark style={{ */}
            {/*                position: 'absolute', */}
            {/*                right: '0', */}
            {/*                bottom: '-46px' */}
            {/*            }} className="iconWrapper"/> */}
            {/* } */}
          </OldInput>
          <Button
            className="primary full-width mt-5"
            submit={true}
            onClick={() => {
              if (
                this.checkFormValidity(this.state, [
                  {
                    name: "login",
                    validationTests: [EValidationTestType.hasValue],
                  },
                ])
              ) {
                this.props.setLoading(true);
                this.sendPasswordResetRequest();
              }
            }}
            buttonType={EButtonType.Button}
            // isLoading={this.props.isLoading}
            // isDisabled={this.props.isLoading}
          >
            <FormattedMessage id="app.submit" defaultMessage="Submit" />
          </Button>
        </form>
      </div>
    );
  }

  renderStep2() {
    return (
      <div className="card mx-auto text-right">
        <img
          alt="logo"
          src={logo1x}
          srcSet={logo1x + " 1x," + logo2x + " 2x"}
          className="login-logo"
        />
        <div className="login-title">
          <FormattedMessage id="app.setnewpassword" defaultMessage="Set new password" />
        </div>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            value="blank"
            placeholder={this.props.intl.formatMessage({
              id: "app.newpassword",
              defaultMessage: "New password",
            })}
            style={{ display: "none" }}
          />

          <OldInput
            id="newPassword"
            name="newPassword"
            value={this.state.newPassword}
            onChange={(name, value) => this.setState({ newPassword: value })}
            validationMessageConfig={this.validationMessageConfig(
              "newPassword",
              {
                message:
                  "The password must be at least 8 characters long and contains at least 1 special character.",
                type: EValidationMessageType.Standard,
              },
              [EValidationTestType.hasValue]
            )}
            type={this.state.passwordType}
            placeholder={this.props.intl.formatMessage({
              id: "app.newpassword",
              defaultMessage: "New password",
            })}
            inputWrapperClass="text-input new-password-input"
            validationTests={[EValidationTestType.isPassword, EValidationTestType.hasValue]}
          >
            <FormattedMessage id="app.newpassword" defaultMessage="New password" />
            <div className="pointer" onClick={() => this.changePasswordInputType()}>
              <IconEye
                className={classNames("icon-show-password", {
                  dark: this.state.passwordType === "text",
                })}
              />
            </div>
          </OldInput>
          <Button
            className="primary full-width new-password-button"
            submit={true}
            onClick={() => {
              if (
                this.checkFormValidity(this.state, [
                  {
                    name: "passwordResetToken",
                    validationTests: [EValidationTestType.hasValue],
                  },
                  {
                    name: "newPassword",
                    validationTests: [EValidationTestType.hasValue],
                  },
                ])
              ) {
                this.props.setLoading(true);
                this.setNewPassword();
              }
            }}
            buttonType={EButtonType.Button}
            // isLoading={this.props.isLoading}
            // isDisabled={this.props.isLoading}
          >
            <FormattedMessage id="app.submit" defaultMessage="Submit" />
          </Button>
        </form>
      </div>
    );
  }

  renderMessage() {
    return (
      <div className="card mx-auto text-right">
        <img
          alt="logo"
          src={logo1x}
          srcSet={logo1x + " 1x," + logo2x + " 2x"}
          className="login-logo"
        />
        <div className="login-title">
          <FormattedMessage id="app.resetpassword" defaultMessage="Reset password" />
        </div>
        <p className="text-left mt-3 pr-4">
          <FormattedMessage
            id="app.wesentanemail"
            defaultMessage="We sent an email with reset link to the following user"
          />
        </p>
        {/* <p className="text-left">Please paste it below</p> */}
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Button
            className="primary full-width mt-5"
            submit={true}
            onClick={() => this.props.history.push({ search: "" })}
            buttonType={EButtonType.Button}
          >
            <FormattedMessage id="app.submit" defaultMessage="Submit" />
          </Button>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="card topCard d-block d-sm-none">
          <img alt="logo" src={logo1x} srcSet={logo1x + " 1x," + logo2x + " 2x"} />
        </div>
        <div className="row">
          {this.state.passwordResetStep === "0" && this.renderLogin()}
          {this.state.passwordResetStep === "1" && this.renderStep1()}
          {this.state.passwordResetStep === "2" && this.renderStep2()}
          {this.state.passwordResetStep === "3" && this.render2fa()}
          {this.state.passwordResetStep === "4" && this.renderMessage()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));

import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import "../../../styles/index.scss";
import Drawer from "@components/Drawer";
import { OldInput } from "@components/OldInput";
import ExpandableRow from "@components/ExpandableRow";
import { Button, EButtonType } from "@components/OldButton";
import { ListContainer } from "@components/ListContainer";
import { ScrollableList } from "@components/ScrollableList";
import { Column } from "@components/ExpandableRow/styles";
import { Text } from "@components/Text";

import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";
import { useFormatMessage } from "@hooks/formatMessage";

import { TDispatch, TRootState } from "../../../redux/types";
import { fetchVersionLogList, incrementVersionLogPageNo, setVersionLogsPageNo } from "./ducks";

const VersionLogs: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [versionLogFilter, setVersionLogFilter] = useState({
    GenericText: null,
    Username: null,
    FirstName: null,
    LastName: null,
    dynamicFilter: "",
  });

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { versionLogsPageNo, moreVersionLogs, versionLogs } = useSelector(
    ({ VersionLogReducer: { versionLogsPageNo, moreVersionLogs, versionLogs } }: TRootState) => ({
      versionLogsPageNo,
      moreVersionLogs,
      versionLogs,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchVersionLogList()).then(() => dispatch(incrementVersionLogPageNo()));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreVersionLogs) {
      dispatch(fetchVersionLogList(versionLogsPageNo)).then(() =>
        dispatch(incrementVersionLogPageNo())
      );
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterVersionLogs = () => {
    dispatch(setVersionLogsPageNo(1));
    dispatch(fetchVersionLogList(1, 10, versionLogFilter));
  };

  const clearFilters = () => {
    setVersionLogFilter(prev => ({
      ...prev,
      dynamicFilter: "",
    }));
    dispatch(setVersionLogsPageNo(1));
    dispatch(fetchVersionLogList());
  };

  const renderVersionLogsSideBar = () => {
    return (
      <Drawer id="filterArea" opened={filtersOpened}>
        <div className="paddingContainer">
          <p className="colorGrey mb-1">Dynamic Filter</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="dynamicSearch"
              name="dynamicSearch"
              key="dynamicSearch"
              inputWrapperClass="text-input filter-input"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={versionLogFilter.dynamicFilter}
              onChange={(name, value) =>
                setVersionLogFilter(prev => ({
                  ...prev,
                  dynamicFilter: value,
                }))
              }
              validationMessageConfig={null}
              type="text"
              filtering
            />
          </div>

          <div className="breaker mb-5" />

          <div className="modal-buttons">
            <Button
              dataCyName="buttonClearSearch"
              className="modal-sub-button primary"
              onClick={e => {
                e.stopPropagation();
                clearFilters();
              }}
              buttonType={EButtonType.ButtonText}
            >
              <FormattedMessage id="app.clear" defaultMessage="Clear" />
            </Button>
            <Button
              dataCyName="buttonSearch"
              className="modal-main-button primary"
              onClick={e => {
                e.stopPropagation();
                filterVersionLogs();
              }}
              buttonType={EButtonType.Button}
            >
              <FormattedMessage id="app.search" defaultMessage="Search" />
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <ListContainer>
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <ScrollableList
          items={versionLogs}
          renderItem={(item, index) => (
            <ExpandableRow
              data-cy="userRow"
              key={`versionLog${index}`}
              id={`versionLog${index}`}
              index={index}
              translucent={false}
              expandable={true}
              top={
                <>
                  <Column size={1}>
                    <Text light={false} fontSize="16px" data-cy="fullVersion">
                      {item.fullVersion}
                    </Text>
                  </Column>
                  <Column size={3}>
                    <Text light={false} fontSize="16px" data-cy="applicationName">
                      {item.applicationName}
                    </Text>
                  </Column>
                  <Column size={3}>
                    <div data-cy="success" className="d-flex flex-row showChildOnHover">
                      <div className="column-text">
                        <OldInput
                          id="TableSuccessInput"
                          name="TableSuccess"
                          isFocused={false}
                          readOnly={true}
                          inputWrapperClass="pt-5"
                          value={item.success || ""}
                          checked={item.success as boolean}
                          type="checkbox"
                          onChange={() => false}
                        />
                      </div>
                    </div>
                  </Column>
                  <Column size={3}>
                    <Text light={false} fontSize="16px" data-cy="message">
                      {item.message}
                    </Text>
                  </Column>
                </>
              }
              more={
                <Flex width="100%" mb="20px">
                  <Flex flexDirection="column" flex={1} mr="20px">
                    <Text mb="20px" data-cy="statement">
                      {formatMessage("app.statement", "Statement")}
                    </Text>
                    <Text light={false} fontSize="16px">
                      {item.statement}
                    </Text>
                  </Flex>
                </Flex>
              }
            />
          )}
          renderHeader={
            <>
              <Column size={1}>
                <Text>{formatMessage("app.fullVersion", "Version")}</Text>
              </Column>
              <Column size={1}>
                <Text>{formatMessage("app.applicationName", "Application")}</Text>
              </Column>
              <Column size={1}>
                <Text>{formatMessage("app.success", "Success")}</Text>
              </Column>
              <Column size={1}>
                <Text>{formatMessage("app.message", "Message")}</Text>
              </Column>
              <Column icon>
                <div
                  id="toggleFilterArea"
                  className="icon-filter"
                  tabIndex={0}
                  onClick={e => {
                    e.stopPropagation();
                    changeFilterOpened();
                  }}
                >
                  <IconFilter />
                </div>
              </Column>
            </>
          }
          onLoadMore={handleScroll}
        />
        {renderVersionLogsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default VersionLogs;

import styled from "@emotion/styled";

import { colors } from "@styles/colors";
import { TRound } from "@components/Round/types";
import { layout, space } from "styled-system";

const Round = styled.div<TRound>`
  ${space};
  ${layout};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  z-index: 1;
  border-radius: 28px;
  border: 2px solid ${({ color }) => color || colors.primary};
  background-color: ${({ color }) => color || colors.primary};
  box-shadow: 5px 5px 14px 0 ${({ color }) => (color ? `${color}66` : `${colors.primary}66`)};
`;

export { Round };

import * as constants from "../constants/index";

export interface RolesState {
  roles: any;
  rolesPageNo: any;
  moreRoles: any;
  filter: any;
  order: Array<string>;
}

const getInitialState = () => {
  return {
    roles: [],
    rolesPageNo: 1,
    moreRoles: true,
    filter: {},
    order: [],
  };
};

// console.log("app.ts", getInitialState());

const roles = (state: RolesState = getInitialState(), action: any) => {
  const orderedRoles: Array<string> = [];

  switch (action.type) {
    case constants.SET_ROLES_PAGE_NO: {
      // console.log("reducer loading", action);
      const moreRoles = !(state.roles.length < (state.rolesPageNo + 1) * constants.PAGINATION_SIZE);
      return { ...state, rolesPageNo: action.payload, moreRoles: moreRoles };
    }

    case constants.FETCHED_ROLES: {
      let roles = [];
      const moreRoles = !(action.payload.length < constants.PAGINATION_SIZE);
      if (state.rolesPageNo === 1 || action.page === 1) {
        roles = [...action.payload];
      } else {
        roles = [...state.roles, ...action.payload];
      }
      return { ...state, roles: roles, moreRoles: moreRoles };
    }

    case constants.SET_FILTERING_CRITERIA_ROLES: {
      if (!action.payload) {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload };
      }
    }

    // Adds, removes or sets ordering criteria
    case constants.SET_ORDER_CRITERIA_ROLES: {
      switch (action.payload.method) {
        case "add":
          if (state.order.includes(action.payload.value)) {
            // if order for a field is set, remove it
            return {
              ...state,
              rolesPageNo: 1,
              order: [...state.order.filter(order => order !== action.payload.value)],
            };
          } else {
            return {
              ...state,
              rolesPageNo: 1,
              order: [...state.order.concat(action.payload.value)],
            };
          }
        case "remove":
          return {
            ...state,
            rolesPageNo: 1,
            order: [...state.order.filter(order => order !== action.payload.value)],
          };
        case "set":
          for (const key in action.payload.value) {
            if (action.payload.value[key] !== "") {
              orderedRoles.push(key + action.payload.value[key]);
            }
          }
          return { ...state, rolesPageNo: 1, order: orderedRoles };
        case "reset":
          return { ...state, rolesPageNo: 1, order: [] };
      }
    }
  }

  return state;
};

export default roles;

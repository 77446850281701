import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Drawer from "@components/Drawer";
import { Text } from "@components/Text";
import { OldInput } from "@components/OldInput";
import { AddButton } from "@components/AddButton";
import ExpandableRow from "@components/ExpandableRow";
import { ListContainer } from "@components/ListContainer";
import { Column } from "@components/ExpandableRow/styles";
import { Button } from "@components/Button";
import { ScrollableList } from "@components/ScrollableList";
import { useSwal } from "@hooks/useSwal";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import { ReactComponent as IconEdit } from "@assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "@assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";

import "../../../styles/index.scss";
import TenantsModal from "./Modal/TenantModal";
import { fetchCompanies } from "../../../actions/company";
import { TDispatch, TRootState } from "../../../redux/types";
import { getParameterByName } from "../../../utils/toSearchParams";
import {
  deleteTenant,
  fetchTenantList,
  getTenantById,
  incrementTenantsPageNo,
  setTenantsPageNo,
} from "./ducks";

enum formMode {
  edit = "edit",
}

const Tenants: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTenantForm, setAddTenantForm] = useState(undefined);
  const [tenantFilter, setTenantFilter] = useState({
    GenericText: null,
    dynamicFilter: "",
  });
  const [companyList, setCompanyList] = useState([]);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const history = useHistory();
  const location = useLocation();
  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { tenants, moreTenants, tenantsPageNo } = useSelector(
    ({ TenantReducer: { tenants, moreTenants, tenantsPageNo } }: TRootState) => ({
      tenants,
      moreTenants,
      tenantsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTenantList()).then(() => dispatch(incrementTenantsPageNo()));
    dispatch(fetchCompanies()).then(res => setCompanyList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTenants) {
      dispatch(fetchTenantList(tenantsPageNo, 10, tenantFilter)).then(() =>
        dispatch(incrementTenantsPageNo())
      );
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterTenants = () => {
    dispatch(setTenantsPageNo(1));
    dispatch(fetchTenantList(1, 10, tenantFilter));
  };

  const clearFilters = () => {
    setTenantFilter(prev => ({
      ...prev,
      dynamicFilter: "",
    }));

    dispatch(setTenantsPageNo(1));
    dispatch(fetchTenantList());
  };

  const renderTenantsSideBar = () => (
    <Drawer id="filterArea" opened={filtersOpened}>
      <div className="paddingContainer">
        <p className="colorGrey mb-1">Dynamic Filter</p>
        <div data-cy="dynamicFilterByField">
          <OldInput
            id="dynamicSearch"
            name="dynamicSearch"
            key="dynamicSearch"
            inputWrapperClass="text-input filter-input"
            placeholder={formatMessage("app.anyfield", "Any Field")}
            value={tenantFilter.dynamicFilter}
            onChange={(name, value) => {
              setTenantFilter(prev => ({
                ...prev,
                dynamicFilter: value,
              }));
            }}
            type="text"
            filtering
          />
        </div>

        <div className="breaker mb-5" />

        <div className="modal-buttons">
          <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
            {formatMessage("app.clear", "Clear")}
          </Button>

          <Button id="buttonSearch" textButton mr="10px" onClick={filterTenants}>
            {formatMessage("app.search", "Search")}
          </Button>
        </div>
      </div>
    </Drawer>
  );

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterTenants();
  };

  const openModal = (content, message?, tenant?) => {
    getTenantById(tenant.id).then(res => {
      if (res.success) {
        setAddTenantForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const saveHistoryAndFilterTenants = () => {
    history.push({
      search: "",
    });
    filterTenants();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterTenants
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this tenant?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove tenant",
    }).then(value => {
      if (value) {
        deleteTenant(id).then(res => {
          if (res.success) showSuccessMessage("deleted");
        });
      }
    });
  };

  const renderModalContent = () =>
    modalIsOpen && (
      <TenantsModal closeModal={closeModal} initialData={addTenantForm} companyList={companyList} />
    );

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {renderModalContent()}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addTenantBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addNewTenant")}
            onClick={() => {
              setAddTenantForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={tenants}
            renderHeader={
              <>
                <Column size={1}>
                  <Text>{formatMessage("app.code")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.name")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.description")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.company")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.insuranceId")}</Text>
                </Column>
                <Column icon>
                  <div
                    id="toggleFilterArea"
                    className="icon-filter"
                    tabIndex={0}
                    onClick={e => {
                      e.stopPropagation();
                      changeFilterOpened();
                    }}
                  >
                    <IconFilter />
                  </div>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                data-cy="tenantRow"
                key={"tenantRow" + index}
                id={"tenant" + index}
                index={index}
                translucent={false}
                expandable={false}
                top={
                  <>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="tenantCode">
                        {item.tenantCode}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="tenantName">
                        {item.tenantName}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="description">
                        {item.description}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="companyName">
                        {item.companyName}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="insuranceId">
                        {item.insuranceId}
                      </Text>
                    </Column>
                    <Column icon>
                      <Flex>
                        <div
                          title="Edit Tenant"
                          data-cy="changeEntry"
                          className="iconWrapper pointer large"
                          onClick={() => openModal(formMode.edit, "", item)}
                        >
                          <IconEdit />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete Tenant"
                          onClick={() => remove(item.id)}
                        >
                          <IconTrash />
                        </div>
                      </Flex>
                    </Column>
                  </>
                }
              />
            )}
            onLoadMore={handleScroll}
          />
        </Flex>
        {renderTenantsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default Tenants;

import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import "./LoginToken.scss";
import "../../styles/index.scss";
import LoginTokensModal from "./modal/LoginTokenModal";
import { generateEmailTokenValidation } from "./ducks";
import { StageEnum } from "../../shared/models/StageEnum";
import { getParameterByName } from "../../utils/toSearchParams";

import { Button } from "@components/Button";
import { ListContainer } from "@components/ListContainer";
import { useFormatMessage } from "@hooks/formatMessage";

const appUrl = process.env.REACT_APP_URL;

const LoginToken: FC = () => {
  const [pageStageEnum, setPageStageEnum] = useState<StageEnum>(StageEnum.RequestEmail);
  const [emailToken, setEmailToken] = useState<string>("");
  const [mobilePhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const location = useLocation();
  const history = useHistory();
  const formatMessage = useFormatMessage();

  useEffect(() => {
    // search for token, set emailToken
    if (getParameterByName("token", location.search) !== "") {
      setEmailToken(getParameterByName("token", location.search));
    }
  }, [location.search, emailToken]);

  // used for open Modal
  useEffect(() => {
    if (emailToken && emailToken !== "") {
      setPageStageEnum(StageEnum.RequestSMSToken);
    }
  }, [emailToken]);

  const closeModal = (newStage, emailSentTo?) => {
    history.replace(`/login-token`);
    setPageStageEnum(newStage);
    setEmail(emailSentTo);
    // always go back to main page after close modal
  };

  const renderModalContent = () => (
    <LoginTokensModal
      closeModal={closeModal}
      initialEmailToken={emailToken}
      mobilePhone={mobilePhone}
    />
  );

  const requestNewToken = () => {
    setEmailToken("");
    setPageStageEnum(StageEnum.RequestEmail);
  };

  const sendLinkAgain = () => {
    const addLoginEmailForm = {
      email: email,
      tenantCode: "TEST",
      urlCallback: `${appUrl}login-token?token=[token]`,
    };

    generateEmailTokenValidation(addLoginEmailForm).then(ret => {
      if (ret.success) {
        setPageStageEnum(StageEnum.EmailSent);
      } else {
        setPageStageEnum(StageEnum.ErrorSendEmail);
      }
    });
  };

  const sendNewEmail = () => {
    setPageStageEnum(StageEnum.RequestEmail);
    setEmailToken("");
    setEmail("");
  };

  // TODO: Change Buttons to TextButtons
  return (
    <ListContainer>
      <Modal
        isOpen={
          pageStageEnum === StageEnum.RequestEmail || pageStageEnum === StageEnum.RequestSMSToken
        }
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {renderModalContent()}
      </Modal>
      {pageStageEnum === StageEnum.EmailSent && (
        <div className={classNames("main-content-container")}>
          <div className="col-12 col-sm-6 mt-5">
            <p>We have sent you a login link to the following email address.</p>
            <p>
              <strong>{email}</strong>
            </p>
            <p>Open the email and click the login link. You will then automatically logged in.</p>
            <p>
              <strong>Didn't get a login link?</strong>
            </p>
            <p>
              Is the email address correct? If so, please check the spam folder in your email
              program.
            </p>
            <p>You can have the login link sent to you again.</p>
            <Button id="sendLinkAgainBtn" onClick={sendLinkAgain}>
              {formatMessage("app.sendlinkagain", "Send login link again")}
            </Button>
          </div>
        </div>
      )}
      {pageStageEnum === StageEnum.ErrorSendSMS && (
        <div className={classNames("main-content-container")}>
          <div className="col-12 col-sm-6 mt-5">
            <p>There was an error locating your token. Please request a new token.</p>
            <Button id="requestNewTokenBtn" onClick={requestNewToken}>
              {formatMessage("app.requestNewToken", "Request new token")}
            </Button>
          </div>
        </div>
      )}
      {pageStageEnum === StageEnum.ErrorSendEmail && (
        <div className={classNames("main-content-container")}>
          <div className="col-12 col-sm-6 mt-5">
            <p>There has been a problem sending a token to</p>
            <p>
              <strong>{email}</strong>
            </p>
            <p>Try again</p>
            <Button id="sendLinkAgainBtn" onClick={sendLinkAgain}>
              {formatMessage("app.sendlinkagain", "Send login link again")}
            </Button>
            <p>Try another email address</p>
            <Button id="sendNewEmailBtn" onClick={sendNewEmail}>
              {formatMessage("app.sendnewwmail", "Try another email address")}
            </Button>
          </div>
        </div>
      )}
    </ListContainer>
  );
};

export default LoginToken;

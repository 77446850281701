import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";

import { Form } from "@components/Form";
import { Button } from "@components/Button";
import { useFormatMessage } from "@hooks/formatMessage";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { FormCheckbox as Checkbox } from "@components/Form/Checkbox";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { ReactComponent as IconArrowRight } from "@assets/icons/arrow-right.svg";

import { TProps } from "./types";
import { ApplicationSchema } from "./schema";
import { changeApplication, createApplication } from "../ducks";
import { fetchApplications } from "../../../../actions/enumerator";
import { bindActionCreators, compose, Dispatch } from "redux";
import { ACTION } from "shared/models/action";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useSwal } from "@hooks/useSwal";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const ApplicationsModal: FC<TProps> = props => {
  const { closeModal, initialData, fetchApplications } = props;

  const [applicationsList, setApplicationsList] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>();
  // Swal
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const formatMessage = useFormatMessage();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    fetchApplications().then(ret => setApplicationsList(ret));
  }, [fetchApplications]);

  const successfulMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: "Application has been " + message + "!",
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const application = {
      ...formData,
      applicationCode: formData.applicationCode ? formData.applicationCode.value : undefined,
    };

    if (isEditing) {
      // Confirm action
      swalConfirmationMessage({
        text: "Do you really want to change this application?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save changes",
      }).then(value => {
        if (value) {
          changeApplication(formData.id, application).then(res => {
            if (res.success) successfulMessage("changed");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this application?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Application",
      }).then(value => {
        if (value) {
          delete application.id;

          createApplication(application).then(res => {
            if (res.success) successfulMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={ApplicationSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="applicationCode"
              name="applicationCode"
              label={formatMessage("app.applicationCode", "Application")}
              options={applicationsList?.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Input id="url" name="url" label={formatMessage("app.url", "Url")} />
            <Checkbox
              id="debuggingMode"
              name="debuggingMode"
              label={formatMessage("app.debuggingMode", "Debugging Mode")}
              wrapperProps={{ width: "20%" }}
            />
          </Flex>
          <Flex mt="30px">
            <Input id="pathLocal" name="pathLocal" label={formatMessage("app.pathLocal", "Path")} />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

function mapDispatchToProps(dispatch: Dispatch<ACTION>) {
  return {
    fetchApplications: bindActionCreators(fetchApplications, dispatch),
  };
}

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(ApplicationsModal);

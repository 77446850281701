import http from "../../../api/http";
import { TApplicationsState } from "./types";
import { TAction } from "../../../redux/types";
import { LOADING, PAGINATION_SIZE } from "../../../constants";
import { buildFilterQueryString, deserialize, serialize } from "../../../api/jsonApiParser";
import { Dispatch } from "redux";

export const FETCHED_APPLICATIONS = "FETCHED_APPLICATIONS";
export const SET_FILTERING_CRITERIA_APPLICATIONS = "SET_FILTERING_CRITERIA_APPLICATIONS";
export const SET_APPLICATIONS_PAGE_NO = "SET_APPLICATIONS_PAGE_NO";
export const INCREMENT_APPLICATIONS_PAGE_NO = "INCREMENT_APPLICATIONS_PAGE_NO";

const initialState = {
  applications: [],
  applicationsPageNo: 1,
  moreApplications: true,
  filter: undefined,
};

const ApplicationsReducer = (state: TApplicationsState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_APPLICATIONS: {
      let applications = [];
      const moreApplications = !(action.payload.length < PAGINATION_SIZE);

      if (state.applicationsPageNo === 1 || action.page === 1) {
        applications = [...action.payload];
      } else {
        applications = [...state.applications, ...action.payload];
      }

      return { ...state, applications: applications, moreApplications: moreApplications };
    }

    case SET_APPLICATIONS_PAGE_NO: {
      const moreApplications = !(
        state.applications.length <
        (state.applicationsPageNo + 1) * PAGINATION_SIZE
      );
      return { ...state, applicationsPageNo: action.payload, moreApplications: moreApplications };
    }

    case SET_FILTERING_CRITERIA_APPLICATIONS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_APPLICATIONS_PAGE_NO: {
      return {
        ...state,
        applicationsPageNo: state.applicationsPageNo + 1,
      };
    }
  }
  return state;
};

export const setApplicationsPageNo = (payload: number): TAction => ({
  type: SET_APPLICATIONS_PAGE_NO,
  payload,
});

export const incrementApplicationsPageNo = () => ({
  type: INCREMENT_APPLICATIONS_PAGE_NO,
});

export const fetchApplicationsList = (
  page = 1,
  pageSize = 10,
  filter?: Record<string, unknown>
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });

    const query = `Application/ApplicationPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: any) => {
        dispatch({
          type: FETCHED_APPLICATIONS,
          payload: response.data.map(applications => deserialize(applications)),
          page: page,
        });

        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const createApplication = payload => {
  return http.post("Application/", serialize(payload, "Application"));
};

export const changeApplication = (id: string, payload) => {
  return http.put("Application/" + id, serialize(payload, "Application"));
};

export const deleteApplication = (id: string) => {
  return http.delete("Application/" + id);
};

export const getApplicationById = (id: string) => {
  return http.get("Application/" + id);
};

export { ApplicationsReducer };

import React, { FC, useEffect, useState } from "react";
import dateFormat from "dateformat";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useFormatMessage } from "@hooks/formatMessage";

import Drawer from "@components/Drawer";
import { OldInput } from "@components/OldInput";
import ExpandableRow from "@components/ExpandableRow";
import { Button as OldButton, EButtonType } from "@components/OldButton/index";

import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";
import { ReactComponent as IconArrowUp } from "@assets/icons/arrow-order-up.svg";
import { ReactComponent as IconArrowDown } from "@assets/icons/arrow-order-down.svg";
import { ReactComponent as IconArrowUpDown } from "@assets/icons/arrow-order-up-down.svg";

import {
  fetchLogonLogsList,
  incrementLogonLogsPageNo,
  setLogonLogsOrderCriteria,
  setLogonLogsPageNo,
} from "./ducks";
import { orderToggleEnum, toggleOrder } from "../../../shared/models/OrderToggleEnum";
import { TDispatch, TRootState } from "../../../redux/types";
import "../../../styles/index.scss";
import { ListContainer } from "@components/ListContainer";
import { ScrollableList } from "@components/ScrollableList";
import { Column } from "@components/ExpandableRow/styles";
import { Text } from "@components/Text";
import { Flex } from "reflexbox";

const LogonLog: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [logonLogListFilter, setLogonLogListFilter] = useState({
    GenericText: null,
    dynamicFilter: "",
  });
  const [logonLogListOrder, setLogonLogListOrder] = useState({
    logonDate: orderToggleEnum.Off,
    logoutDate: orderToggleEnum.Off,
  });

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { logonLogsPageNo, logonLogs, moreLogonLogs } = useSelector(
    ({ LogonLogReducer: { logonLogsPageNo, logonLogs, moreLogonLogs } }: TRootState) => ({
      logonLogsPageNo,
      logonLogs,
      moreLogonLogs,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchLogonLogsList()).then(() => dispatch(incrementLogonLogsPageNo()));
  }, [dispatch, logonLogListFilter]);

  const handleScroll = () => {
    if (moreLogonLogs) {
      dispatch(fetchLogonLogsList(logonLogsPageNo)).then(() =>
        dispatch(incrementLogonLogsPageNo())
      );
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterLogonLogs = () => {
    dispatch(setLogonLogsPageNo(1));
    dispatch(fetchLogonLogsList());
  };

  const clearLogonLogsFilters = () => {
    setLogonLogListFilter(prev => ({
      ...prev,
      dynamicFilter: "",
    }));
    dispatch(setLogonLogsPageNo(1));
    dispatch(fetchLogonLogsList());
  };

  const orderArrow = order => {
    switch (order) {
      case orderToggleEnum.Ascending:
        return <IconArrowUp />;
      case orderToggleEnum.Descending:
        return <IconArrowDown />;
      case orderToggleEnum.Off:
        return <IconArrowUpDown />;
    }
  };

  const setOrderLogonLog = name => {
    setLogonLogListOrder(prev => ({ ...prev, [name]: toggleOrder(logonLogListOrder[name]) }));
    dispatch(
      setLogonLogsOrderCriteria({
        method: "set",
        value: logonLogListOrder,
      })
    );
    dispatch(fetchLogonLogsList());
  };

  const renderLogonLogsSideBar = () => {
    return (
      <Drawer id="filterArea" opened={filtersOpened}>
        <div className="paddingContainer">
          <p className="colorGrey mb-1">Dynamic Filter</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="dynamicSearch"
              name="dynamicSearch"
              key="dynamicSearch"
              inputWrapperClass="text-input filter-input"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={logonLogListFilter.dynamicFilter}
              onChange={(name, value) =>
                setLogonLogListFilter(prev => ({
                  ...prev,
                  dynamicFilter: value,
                }))
              }
              validationMessageConfig={null}
              type="text"
              inputWrapperStyle={{}}
              filtering
            />
          </div>

          <div className="breaker mb-5" />

          <div className="modal-buttons">
            <OldButton
              dataCyName="buttonClearSearch"
              className="modal-sub-button primary"
              onClick={e => {
                e.stopPropagation();
                clearLogonLogsFilters();
              }}
              buttonType={EButtonType.ButtonText}
            >
              <FormattedMessage id="app.clear" defaultMessage="Clear" />
            </OldButton>
            <OldButton
              dataCyName="buttonSearch"
              className="modal-main-button primary"
              onClick={e => {
                e.stopPropagation();
                filterLogonLogs();
              }}
              buttonType={EButtonType.Button}
            >
              <FormattedMessage id="app.search" defaultMessage="Search" />
            </OldButton>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <ListContainer>
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <ScrollableList
          items={logonLogs}
          onLoadMore={handleScroll}
          renderHeader={
            <>
              <Column size={1}>
                <Text>{formatMessage("app.username", "Username")}</Text>
              </Column>
              <Column size={2}>
                <Text>{formatMessage("app.logonResult", "Logon Result")}</Text>
              </Column>
              <Column size={2} onClick={() => setOrderLogonLog("logonDate")}>
                <Text>{formatMessage("app.logonDate", "Logon date")}</Text>
                {orderArrow(logonLogListOrder.logonDate)}
              </Column>
              <Column size={2} onClick={() => setOrderLogonLog("logoutDate")}>
                <Text>{formatMessage("app.logoutDate", "Logout date")}</Text>
                {orderArrow(logonLogListOrder.logoutDate)}
              </Column>
              <Column size={3}>
                <Text>{formatMessage("app.clientIP", "IP Address")}</Text>
              </Column>
              <Column icon>
                <div
                  id="toggleFilterArea"
                  className="icon-filter"
                  tabIndex={0}
                  onClick={e => {
                    e.stopPropagation();
                    changeFilterOpened();
                  }}
                >
                  <IconFilter />
                </div>
              </Column>
            </>
          }
          renderItem={(item, index) => (
            <ExpandableRow
              data-cy="userRow"
              key={"logonRow" + index}
              id={`logonLog${index}`}
              index={index}
              translucent={false}
              expandable={false}
              top={
                <>
                  <Column size={1}>
                    <div data-cy="firstname" className="d-flex flex-row">
                      <Text light={false} fontSize="16px">
                        {item.username}
                      </Text>
                    </div>
                  </Column>
                  <Column size={2}>
                    <div data-cy="lastname" className={"d-flex flex-row showChildOnHover "}>
                      <Text light={false} fontSize="16px">
                        {item.logonResultName}
                      </Text>
                    </div>
                  </Column>
                  <Column size={2}>
                    <div data-cy="username" className="d-flex flex-row showChildOnHover">
                      <Text light={false} fontSize="16px">
                        {dateFormat(item.logonDate, "yyyy/mm/dd HH:mm:ss")}
                      </Text>
                    </div>
                  </Column>
                  <Column size={2}>
                    <div data-cy="company" className="d-flex flex-row showChildOnHover">
                      <Text light={false} fontSize="16px">
                        {" "}
                        {dateFormat(item.logoutDate, "yyyy/mm/dd HH:mm:ss")}
                      </Text>
                    </div>
                  </Column>
                  <Column size={5}>
                    <div data-cy="role" className="d-flex flex-row showChildOnHover">
                      <Text light={false} fontSize="16px">
                        {item.clientIpAddress}
                      </Text>
                    </div>
                  </Column>
                  <Column icon />
                </>
              }
            />
          )}
        />
        {renderLogonLogsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default LogonLog;

import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useFormatMessage } from "@hooks/formatMessage";

import Drawer from "@components/Drawer";
import Header from "@components/Header";
import { Text } from "@components/Text";
import { OldInput } from "@components/OldInput";
import { AddButton } from "@components/AddButton";
import ExpandableRow from "@components/ExpandableRow";
import { Column } from "@components/ExpandableRow/styles";
import { ListContainer } from "@components/ListContainer";
import { ScrollableList } from "@components/ScrollableList";
import { Button } from "@components/Button";

import CompaniesModal from "./Modal/CompanyModal";
import { deserialize } from "@api/jsonApiParser";
import { TDispatch, TRootState } from "../../redux/types";
import { getParameterByName } from "../../utils/toSearchParams";
import { ReactComponent as IconEdit } from "../../assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "../../assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "../../assets/icons/filter.svg";
import {
  deleteCompany,
  fetchCompanyList,
  getCompanyById,
  incrementCompaniesPageNo,
  setCompaniesPageNo,
} from "./ducks";
import "../../styles/index.scss";
import { useSwal } from "@hooks/useSwal";

enum formMode {
  edit = "edit",
  password = "password",
}

const Companies: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addCompanyForm, setAddCompanyForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [companyFilter, setCompanyFilter] = useState({
    GenericText: null,
    Username: null,
    FirstName: null,
    LastName: null,
    dynamicFilter: "",
  });

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { companyList, moreCompanies, companiesPageNo } = useSelector(
    ({ CompaniesReducer: { companies, moreCompanies, companiesPageNo } }: TRootState) => ({
      companyList: companies,
      moreCompanies,
      companiesPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchCompanyList()).then(() => dispatch(incrementCompaniesPageNo()));
  }, [dispatch]);

  const handleLoadMore = () => {
    if (moreCompanies) {
      dispatch(fetchCompanyList(companiesPageNo, 10, companyFilter)).then(() => {
        dispatch(incrementCompaniesPageNo());
      });
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prevState => !prevState);
  };

  const filterConnections = () => {
    dispatch(setCompaniesPageNo(1));
    dispatch(fetchCompanyList(1, 10, companyFilter));
  };

  const saveHistoryAndFilterCompanies = () => {
    history.push({
      search: "",
    });
    filterConnections();
  };

  const clearFilters = () => {
    setCompanyFilter({
      GenericText: null,
      Username: null,
      FirstName: null,
      LastName: null,
      dynamicFilter: "",
    });

    dispatch(setCompaniesPageNo(1));
    dispatch(fetchCompanyList());
  };

  const openModal = (content, message?, entry?) => {
    getCompanyById(entry.id).then(ret => {
      if (ret.success) {
        setAddCompanyForm(deserialize(ret.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterCompanies();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const successfulMessage = (message: string, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `Company has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this company?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove company",
    }).then(value => {
      if (value) {
        deleteCompany(id).then(ret => {
          if (ret.success) successfulMessage("deleted", saveHistoryAndFilterCompanies);
        });
      }
    });
  };

  return (
    <ListContainer>
      <Header title="Companies" />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && <CompaniesModal closeModal={closeModal} initialData={addCompanyForm} />}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addCompanyBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewcompany", "Add Company")}
            onClick={() => {
              setAddCompanyForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={companyList}
            onLoadMore={handleLoadMore}
            renderHeader={
              <>
                <Column>
                  <Text>{formatMessage("app.companyName", "Name")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.parentCompanyName", "Parent Company")}</Text>
                </Column>
                <Column icon>
                  <Text>
                    <div
                      id="toggleFilterArea"
                      className="icon-filter"
                      tabIndex={0}
                      onClick={e => {
                        e.stopPropagation();
                        changeFilterOpened();
                      }}
                    >
                      <IconFilter />
                    </div>
                  </Text>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                data-cy="userRow"
                key={"userRow" + index}
                id={item.id as string}
                translucent={false}
                expandable={false}
                top={
                  <>
                    <Column>
                      <div data-cy="companyName" className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.companyName}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div data-cy="parentCompanyName" className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.parentCompanyName}
                        </Text>
                      </div>
                    </Column>
                    <Column icon>
                      <div className="d-flex flex-row showChildOnHover">
                        <div
                          data-cy="changeEntry"
                          className="iconWrapper pointer large"
                          title="Edit Company"
                          style={{}}
                          onClick={() => openModal(formMode.edit, "", item)}
                        >
                          <IconEdit />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete Company"
                          style={{}}
                          onClick={() => remove(item.id)}
                        >
                          <IconTrash />
                        </div>
                      </div>
                    </Column>
                  </>
                }
              />
            )}
          />
        </Flex>
        <Drawer id="filterArea" opened={filtersOpened}>
          <div className="paddingContainer">
            <p className="colorGrey mb-1">Dynamic Filter</p>
            <div data-cy="dynamicFilterByField">
              <OldInput
                id="dynamicSearch"
                name="dynamicSearch"
                key="dynamicSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.anyfield", "Any Field")}
                value={companyFilter.dynamicFilter}
                onChange={(name, value) =>
                  setCompanyFilter(prevState => ({ ...prevState, dynamicFilter: value }))
                }
                validationMessageConfig={null /* this.validationMessageConfig() */}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>

            <div className="breaker mb-5" />

            <div className="modal-buttons">
              <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
                {formatMessage("app.clear", "Clear")}
              </Button>

              <Button id="buttonSearch" textButton mr="10px" onClick={filterConnections}>
                {formatMessage("app.search", "Search")}
              </Button>
            </div>
          </div>
        </Drawer>
      </Flex>
    </ListContainer>
  );
};

export default Companies;

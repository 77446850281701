import React, { FC, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Drawer from "@components/Drawer";
import { OldInput } from "@components/OldInput";
import { deserialize } from "@api/jsonApiParser";
import ExpandableRow from "../../../components/ExpandableRow";
import TranslationsModal from "./Modal/TranslationModal";
import { Button } from "../../../components/Button";
import { ReactComponent as IconEdit } from "../../../assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "../../../assets/icons/filter.svg";
import { getParameterByName } from "../../../utils/toSearchParams";
import {
  deleteTranslation,
  fetchTranslationList,
  getTranslationById,
  incrementTranslationsPageNo,
  setTranslationsPageNo,
} from "./ducks";
import {
  fetchBaseTextTranslates,
  fetchLanguages,
  fetchLayers,
  fetchModules,
  fetchTranslationTypes,
} from "../../../actions/enumerator";
import "../../../styles/index.scss";
import { TDispatch, TRootState } from "../../../redux/types";
import { useSwal } from "@hooks/useSwal";

import { useFormatMessage } from "@hooks/formatMessage";
import { ListContainer } from "@components/ListContainer";
import { ScrollableList } from "@components/ScrollableList";
import { Column } from "@components/ExpandableRow/styles";
import { Text } from "@components/Text";
import { AddButton } from "@components/AddButton";

enum formMode {
  edit = "edit",
}

const Translations: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTranslationForm, setAddTranslationForm] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [baseTextTranslateIdList, setBaseTextTranslateIdList] = useState([]);
  const [translationTypeList, setTranslationTypeList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [layerList, setLayerList] = useState([]);
  const [translationFilter, setTranslationFilter] = useState({
    GenericText: null,
    Layer: null,
    Module: null,
    TranslationType: null,
    Language: null,
    TranslationId: null,
    TextTranslated: null,
    ListNotTranslated: null,
    translationId: null,
    dynamicFilter: "",
  });
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const history = useHistory();
  const location = useLocation();
  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { translations, moreTranslations, translationsPageNo } = useSelector(
    ({
      TranslationsReducer: { translations, moreTranslations, translationsPageNo },
    }: TRootState) => ({
      translations,
      moreTranslations,
      translationsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTranslationList()).then(() => dispatch(incrementTranslationsPageNo()));
    dispatch(fetchLanguages()).then(res => setLanguageList(res));
    dispatch(fetchBaseTextTranslates()).then(res => setBaseTextTranslateIdList(res));
    dispatch(fetchTranslationTypes()).then(res => setTranslationTypeList(res));
    dispatch(fetchModules()).then(res => setModuleList(res));
    dispatch(fetchLayers()).then(res => setLayerList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTranslations) {
      dispatch(fetchTranslationList(translationsPageNo, 10, translationFilter)).then(() =>
        dispatch(incrementTranslationsPageNo())
      );
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterTranslations = () => {
    dispatch(setTranslationsPageNo(1));
    dispatch(fetchTranslationList(1, 10, translationFilter));
  };

  const clearFilters = () => {
    setTranslationFilter({
      GenericText: null,
      Layer: null,
      Module: null,
      TranslationType: null,
      Language: null,
      TranslationId: null,
      TextTranslated: null,
      ListNotTranslated: null,
      translationId: null,
      dynamicFilter: "",
    });

    dispatch(setTranslationsPageNo(1));
    dispatch(fetchTranslationList());
  };

  const prepareLanguageOptions = useCallback(() => {
    if (languageList) {
      return languageList.map(item => ({
        id: item.id,
        dropdownLabel: item.description,
      }));
    }
  }, [languageList]);

  const prepareModuleOptions = useCallback(() => {
    if (moduleList) {
      return moduleList.map(item => ({
        id: item.id,
        dropdownLabel: item.description,
      }));
    }
  }, [moduleList]);

  const prepareTranslationTypeOptions = useCallback(() => {
    if (translationTypeList) {
      return translationTypeList.map(item => ({
        dropdownLabel: item.description,
        id: item.id,
      }));
    }
  }, [translationTypeList]);

  const prepareLayerOptions = useCallback(() => {
    if (layerList) {
      return layerList.map(item => ({
        id: item.id,
        dropdownLabel: item.description,
      }));
    }
  }, [layerList]);

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({
      search: "",
    });
    filterTranslations();
  };

  const openModal = (content, message?, translation?) => {
    getTranslationById(translation.id).then(res => {
      if (res.success) {
        setAddTranslationForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const saveHistoryAndFilterTranslations = () => {
    history.push({
      search: "",
    });
    filterTranslations();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Translation has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterTranslations
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this translation?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove translation",
    }).then(value => {
      if (value) {
        deleteTranslation(id).then(res => {
          if (res.success) showSuccessMessage("deleted");
        });
      }
    });
  };

  const renderTranslationsSideBar = () => {
    const languageOptions = prepareLanguageOptions();
    const layerOptions = prepareLayerOptions();
    const moduleOptions = prepareModuleOptions();
    const translationTypeOptions = prepareTranslationTypeOptions();

    return (
      <Drawer id="filterArea" opened={filtersOpened}>
        <div className="paddingContainer">
          <p className="colorGrey mb-1">Dynamic Filter</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="dynamicSearch"
              name="dynamicSearch"
              key="dynamicSearch"
              inputWrapperClass="text-input filter-input"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={translationFilter.dynamicFilter}
              onChange={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  dynamicFilter: value,
                }))
              }
              validationMessageConfig={null}
              type="text"
              filtering
            />
          </div>

          <div className="breaker mb-5" />

          <p className="colorGrey mb-1">{formatMessage("app.Layer", "Layer")}</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="LayerInput"
              name="Layer"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                translationFilter.Layer
                  ? layerOptions.find(x => x.id === translationFilter.Layer).dropdownLabel
                  : ""
              }
              selectOptions={layerOptions}
              onSelected={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  Layer: value,
                }))
              }
              onChange={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  Layer: value,
                }))
              }
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1 mt-5">{formatMessage("app.Module", "Module")}</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="ModuleInput"
              name="Module"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                translationFilter.Module
                  ? moduleOptions.find(x => x.id === translationFilter.Module).dropdownLabel
                  : ""
              }
              selectOptions={moduleOptions}
              onSelected={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  Module: value,
                }))
              }
              onChange={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  Module: value,
                }))
              }
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1 mt-5">
            {formatMessage("app.TranslationType", "Translation Type")}
          </p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="TranslationTypeInput"
              name="TranslationType"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                translationFilter.TranslationType
                  ? translationTypeOptions.find(x => x.id === translationFilter.TranslationType)
                      .dropdownLabel
                  : ""
              }
              selectOptions={translationTypeOptions}
              onSelected={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  TranslationType: value,
                }))
              }
              onChange={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  TranslationType: value,
                }))
              }
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1 mt-5">{formatMessage("app.language", "Language")}</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="languageInput"
              name="language"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                translationFilter.Language
                  ? languageOptions.find(x => x.id === translationFilter.Language).dropdownLabel
                  : ""
              }
              selectOptions={languageOptions}
              onSelected={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  Language: value,
                }))
              }
              onChange={(name, value) =>
                setTranslationFilter(prev => ({
                  ...prev,
                  Language: value,
                }))
              }
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1 mt-5">
            {formatMessage("app.translationId", "Translation Id")}
          </p>
          <div data-cy="dynamicFilterByField">
            <div className="inputContainer">
              <input
                id="translationIdInput"
                name="translationId"
                className="inputText textMaterial "
                type="text"
                value={translationFilter.translationId || ""}
                onChange={e =>
                  setTranslationFilter(prev => ({
                    ...prev,
                    translationId: e.currentTarget.value,
                  }))
                }
              />
            </div>
          </div>
          <p className="colorGrey mb-1 mt-5">
            {formatMessage("app.textTranslated", "Text translated")}
          </p>
          <div data-cy="dynamicFilterByField">
            <div className="inputContainer">
              <input
                id="textTranslatedInput"
                name="textTranslated"
                className="inputText textMaterial "
                type="text"
                value={translationFilter.TextTranslated || ""}
                onChange={e =>
                  setTranslationFilter(prev => ({
                    ...prev,
                    TextTranslated: e.currentTarget.value,
                  }))
                }
              />
            </div>
          </div>
          <p className="colorGrey mb-1 mt-5"></p>
          <div data-cy="dynamicFilterByField">
            <label
              htmlFor="ListNotTranslated"
              className="checkboxLabel"
              onKeyPress={event => {
                const code = event.keyCode || event.which;
                if (code === 13) {
                  setTranslationFilter(prev => ({
                    ...prev,
                    ListNotTranslated: !prev.ListNotTranslated,
                  }));
                }
              }}
            >
              <label
                className="inputLabel"
                onClick={() =>
                  setTranslationFilter(prev => ({
                    ...prev,
                    ListNotTranslated: !prev.ListNotTranslated,
                  }))
                }
              >
                {formatMessage("app.listNotTranslated", "List not translated")}
              </label>
              <input
                id="ListNotTranslatedInput"
                name="ListNotTranslated"
                tabIndex={-1}
                className="checkboxInput"
                type="checkbox"
                value={translationFilter.ListNotTranslated || ""}
                checked={translationFilter.ListNotTranslated || false}
                onChange={e =>
                  setTranslationFilter(prev => ({
                    ...prev,
                    ListNotTranslated: e.currentTarget.checked,
                  }))
                }
              />
              <i
                onClick={() =>
                  setTranslationFilter(prev => ({
                    ...prev,
                    ListNotTranslated: !prev.ListNotTranslated,
                  }))
                }
                className={"checkboxMark " + (translationFilter.ListNotTranslated ? "checked" : "")}
              />
            </label>
          </div>
          <div className="breaker mb-5" />

          <div className="modal-buttons">
            <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
              {formatMessage("app.clear", "Clear")}
            </Button>

            <Button id="buttonSearch" textButton mr="10px" onClick={filterTranslations}>
              {formatMessage("app.search", "Search")}
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {modalIsOpen && (
          <TranslationsModal
            closeModal={closeModal}
            initialData={addTranslationForm}
            languageList={languageList}
            baseTextTranslateIdList={baseTextTranslateIdList}
            translationTypeList={translationTypeList}
            moduleList={moduleList}
            layerList={layerList}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addTranslationBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewtranslation", "Add Translation")}
            onClick={() => {
              setAddTranslationForm("");
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={translations}
            renderHeader={
              <>
                <Column size={1}>
                  <Text>{formatMessage("app.layerName", "Layer")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.moduleName", "Module")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.languageCodeIso", "Language")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.originalText", "Text")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.textTranslated", "Translated")}</Text>
                </Column>
                <Column icon>
                  <div
                    id="toggleFilterArea"
                    className="icon-filter"
                    tabIndex={0}
                    onClick={e => {
                      e.stopPropagation();
                      changeFilterOpened();
                    }}
                  >
                    <IconFilter />
                  </div>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                id={`translation${index}`}
                key={`translation${index}`}
                index={index}
                translucent={false}
                expandable={true}
                top={
                  <>
                    <Column size={1}>
                      <Text light={false} fontSize="16px" data-cy="layerName">
                        {item.layerName}
                      </Text>
                    </Column>
                    <Column size={1}>
                      <Text light={false} fontSize="16px" data-cy="moduleName">
                        {item.moduleName}
                      </Text>
                    </Column>
                    <Column size={1}>
                      <Text light={false} fontSize="16px" data-cy="languageCodeIso">
                        {item.languageCodeIso}
                      </Text>
                    </Column>
                    <Column size={1}>
                      <Text light={false} fontSize="16px" data-cy="originalText">
                        {item.originalText}
                      </Text>
                    </Column>
                    <Column size={1}>
                      <Text light={false} fontSize="16px" data-cy="textTranslated">
                        {item.textTranslated}
                      </Text>
                    </Column>
                    <Column icon>
                      <div className="d-flex flex-row showChildOnHover">
                        <div
                          data-cy="changeEntry"
                          className="iconWrapper pointer large"
                          title="Edit Translation"
                          onClick={() => openModal(formMode.edit, "", item)}
                        >
                          <IconEdit />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete Translation"
                          onClick={() => remove(item.id)}
                        >
                          <IconTrash />
                        </div>
                      </div>
                    </Column>
                  </>
                }
                more={
                  <>
                    <Flex width="100%" mb="20px">
                      <Flex flexDirection="column" flex={1} mr="20px">
                        <Text mb="20px" data-cy="translationTypeName">
                          {formatMessage("app.translationTypeName", "Translation Type")}
                        </Text>
                        <Text light={false} fontSize="16px">
                          {item.translationTypeName}
                        </Text>
                      </Flex>
                      <Flex flexDirection="column" flex={1} mr="20px">
                        <Text mb="20px" data-cy="translationId">
                          {formatMessage("app.translationId", "Translation Id")}
                        </Text>
                        <Text light={false} fontSize="16px">
                          {item.translationId}
                        </Text>
                      </Flex>

                      <Flex flexDirection="column" flex={1} mr="20px">
                        <Text mb="20px" data-cy="automaticTranslation">
                          {formatMessage("app.automaticTranslation", "Automatic Translation")}
                        </Text>
                        <div className="value">
                          <OldInput
                            id="TableAutomaticTranslationInput"
                            name="TableAutomaticTranslation"
                            isFocused
                            readOnly={true}
                            inputWrapperClass="w-100 high"
                            value={item.automaticTranslation || ""}
                            checked={item.automaticTranslation as boolean}
                            type="checkbox"
                            onChange={() => false}
                          />
                        </div>
                      </Flex>
                    </Flex>
                  </>
                }
              />
            )}
            onLoadMore={handleScroll}
          />
        </Flex>
        {renderTranslationsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default Translations;

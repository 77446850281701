import apiCaller from "../api/apiHandler";
import * as constants from "../constants";
import requestWraper from "../api/requestWraper";
import { deserialize } from "../api/jsonApiParser";

export const fetchCompanies = (): any =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });

      return await apiCaller("GET", `Company/`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(user => deserialize(user));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Something went wrong!";
    }
  });

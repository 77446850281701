import { Dispatch } from "redux";

import http from "../../api/http";
import { TCompaniesState } from "./types";
import { TResponse } from "../../api/types";
import { TAction } from "../../redux/types";
import { LOADING, PAGINATION_SIZE } from "../../constants";
import { buildFilterQueryString, deserialize, serialize } from "../../api/jsonApiParser";

export const FETCHED_COMPANIES = "FETCHED_COMPANIES";
export const SET_FILTERING_CRITERIA_COMPANIES = "SET_FILTERING_CRITERIA_COMPANIES";
export const SET_COMPANIES_PAGE_NO = "SET_COMPANIES_PAGE_NO";
export const INCREMENT_COMPANIES_PAGE_NO = "INCREMENT_COMPANIES_PAGE_NO";

const initialState = {
  companies: [],
  companiesPageNo: 1,
  moreCompanies: true,
  filter: undefined,
};

export const setCompaniesPageNo = (payload: number): TAction => ({
  type: SET_COMPANIES_PAGE_NO,
  payload,
});

export const incrementCompaniesPageNo = () => ({
  type: INCREMENT_COMPANIES_PAGE_NO,
});

export const fetchCompanyList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });
    const query = `Company/CompanyPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: any) => {
        dispatch({
          type: FETCHED_COMPANIES,
          payload: response.data.map(connection => deserialize(connection)),
          page: page,
        });

        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const createCompany = (payload): Promise<TResponse> => {
  return http.post("Company/", serialize(payload, "Company"));
};

export const changeCompany = (id: string, payload): Promise<TResponse> => {
  return http.put("Company/" + id, serialize(payload, "Company"));
};

export const deleteCompany = (id: string): Promise<TResponse> => {
  return http.delete("Company/" + id);
};

export const getCompanyById = (id: string): Promise<TResponse> => {
  return http.get("Company/" + id);
};

export const CompaniesReducer = (state: TCompaniesState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_COMPANIES: {
      let companies = [];
      const moreCompanies = !(action.payload.length < PAGINATION_SIZE);

      if (state.companiesPageNo === 1 || action.page === 1) {
        companies = [...action.payload];
      } else {
        companies = [...state.companies, ...action.payload];
      }

      return { ...state, companies: companies, moreCompanies: moreCompanies };
    }

    case SET_COMPANIES_PAGE_NO: {
      const moreCompanies = !(
        state.companies.length <
        (state.companiesPageNo + 1) * PAGINATION_SIZE
      );
      return { ...state, companiesPageNo: action.payload, moreCompanies: moreCompanies };
    }

    case SET_FILTERING_CRITERIA_COMPANIES: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_COMPANIES_PAGE_NO: {
      return {
        ...state,
        companiesPageNo: state.companiesPageNo + 1,
      };
    }
  }
  return state;
};

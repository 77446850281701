import React, { FC } from "react";

import { StyledAddButton } from "./styles";

import { Icon } from "@components/Icon";
import { Round } from "@components/Round";
import { TAddButton } from "@components/AddButton/types";

import { ReactComponent as IconPlus } from "@assets/icons/plus.svg";

const AddButton: FC<TAddButton> = ({ label, ...rest }) => (
  <StyledAddButton {...rest}>
    <Round mr="10px">
      <Icon icon={IconPlus} width="18px" height="18px" />
    </Round>
    {label && label}
  </StyledAddButton>
);

export { AddButton };

import React, { FC, useEffect } from "react";
import { injectIntl } from "react-intl";

import { Button as OldButton, EButtonType } from "@components/OldButton";
import { ReactComponent as IconArrowRight } from "../../../../assets/icons/arrow-right.svg";
// , validateConnection
import { compose } from "redux";
import { connect } from "react-redux";

import { Flex } from "reflexbox";
import { Form } from "@components/Form";
import { Input } from "@components/Input";
import { Checkbox } from "@components/Checkbox";
import { Button } from "@components/Button";
import { useFormatMessage } from "@hooks/formatMessage";
import { useSwal } from "@hooks/useSwal";
import { setLocale } from "yup";

import { TProps } from "./types";
import { ConnectionsPasswordSchema } from "./schema";
import { changePasswordConnection, validateConnection } from "../ducks";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const ConnectionsModalPassword: FC<TProps> = props => {
  // Props
  const { closeModal, initialData } = props;
  // Language
  const formatMessage = useFormatMessage();
  // Swal
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const onSubmit = (formData, event) => {
    if (event.nativeEvent.submitter.id === "connectionsModalTestBtn") {
      const connection = {
        ...initialData,
        password: formData.password,
      };

      validateConnection(connection).then(ret => {
        if (ret.success) {
          swalAlertWithCallback(
            {
              text: "Connection tested with success.",
              type: "success",
              confirmationText: "Ok!",
            },
            closeModal
          );
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to change connection's password?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Change!",
      }).then(value => {
        if (value) {
          changePasswordConnection(formData.id, formData.password).then(ret => {
            if (ret.success) {
              swalAlertWithCallback(
                {
                  text: "Password has been changed!",
                  type: "success",
                  confirmationText: "Ok!",
                },
                closeModal
              );
            }
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            {formatMessage("app.backtooverview", "Zurück zur Übersicht")}
          </OldButton>
        </div>
      </div>
      <Form onSubmit={onSubmit} schema={ConnectionsPasswordSchema} defaultValues={initialData}>
        <Flex>
          <Input id="id" name="id" hidden />
          <Input
            id="password"
            name="password"
            type="password"
            label={formatMessage("app.password", "Password")}
          />
          <Input
            id="passwordrepeat"
            name="passwordrepeat"
            type="password"
            width="75%"
            label={formatMessage("app.passwordRepeat", "Repeat Password")}
          />
        </Flex>
        <Flex>
          <Checkbox
            id="mustChangePwd"
            name="mustChangePwd"
            label={formatMessage("app.passwordMustBeChanged", "Password must be changed")}
            wrapperProps={{ width: "25%" }}
          />
        </Flex>

        <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
          <Button id="connectionsModalTestBtn" type="submit">
            {formatMessage("app.testConnection", "Test Connection")}
          </Button>
          <Button id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
            {formatMessage("app.cancel", "Cancel")}
          </Button>
          <Button id="ModalBtn_save" type="submit">
            {formatMessage("app.save", "Save")}
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

const enhance = compose(connect(null, null), injectIntl);

export default enhance(ConnectionsModalPassword);

import { History } from "history";
import { intlReducer } from "react-intl-redux";
import { combineReducers, Reducer } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import AppReducer from "../reducers/app";
import RolesReducer from "../reducers/roles";
import { connectRouter } from "connected-react-router";
import LocalesReducer from "../reducers/i18n";
import LoginReducer from "../shared/state/AuthenticateReducers";
import { MailServerSettingsReducer } from "../features/Configuration/MailServerSettings/ducks";
import { ConnectionsReducer } from "../features/Configuration/Connections/ducks";
import { WebServicesReducer } from "../features/Configuration/WebServices/ducks";
import { CompaniesReducer } from "../features/Company/ducks";
import { ObjectRolesReducer } from "../features/Configuration/ObjectRoles/ducks";
import { TranslationsReducer } from "../features/SystemConfiguration/Translation/ducks";
import { UsersReducer } from "../features/User/Users/ducks";
import { LogonLogReducer } from "../features/Logs/LogonLog/ducks";
import { EmailLogsReducer } from "../features/Logs/EmailLog/ducks";
import { SMSLogReducer } from "../features/Logs/SmsLog/ducks";
import { SessionReducer } from "../features/Session/ducks";
import { TenantMappingsReducer } from "../features/Tenant/TenantMappings/ducks";
import { TenantSettingsReducer } from "../features/Tenant/TenantSettings/ducks";
import { ObjectsReducer } from "../features/Configuration/Objects/ducks";
import { TenantReducer } from "features/Tenant/Tenant/ducks";
import { TenantTranslationReducer } from "../features/Tenant/TenantTranslations/ducks";
import { VersionLogReducer } from "../features/Logs/VersionLog/ducks";
import { VersionReducer } from "../features/SystemConfiguration/Versions/ducks";
import { ApplicationsReducer } from "../features/Configuration/Application/ducks";
import { FileStructureReducer } from "../features/FileStructure/ducks";

const createReducer = (history: History): Reducer =>
  combineReducers({
    AppReducer,
    RolesReducer,
    LocalesReducer,
    LoginReducer,
    MailServerSettingsReducer,
    ConnectionsReducer,
    WebServicesReducer,
    CompaniesReducer,
    ObjectsReducer,
    ObjectRolesReducer,
    TranslationsReducer,
    UsersReducer,
    LogonLogReducer,
    EmailLogsReducer,
    SMSLogReducer,
    SessionReducer,
    TenantReducer,
    TenantMappingsReducer,
    TenantSettingsReducer,
    TenantTranslationReducer,
    VersionLogReducer,
    VersionReducer,
    ApplicationsReducer,
    FileStructureReducer,
    router: connectRouter(history),
    intl: intlReducer,
    toastr: toastrReducer,
  });

export default createReducer;

import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { bindActionCreators, compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { Form } from "@components/Form";
import { Button } from "@components/Button";
import { useFormatMessage } from "@hooks/formatMessage";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { FormCheckbox as Checkbox } from "@components/Form/Checkbox";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { ReactComponent as IconArrowRight } from "@assets/icons/arrow-right.svg";
import { useSwal } from "@hooks/useSwal";

import { TProps } from "./types";
import { WebServiceSchema } from "./schema";
import {
  fetchAuthenticationTypes,
  fetchWebServiceBindingTypes,
} from "../../../../actions/enumerator";
import { ACTION } from "shared/models/action";
import { changeWebservice, createWebservice } from "../ducks";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const WebServicesModal: FC<TProps> = props => {
  const { fetchAuthenticationTypes, fetchWebServiceBindingTypes, closeModal, initialData } = props;
  const [isEditing, setIsEditing] = useState<boolean>();
  const [authenticationTypesList, setAuthenticationTypesList] = useState<any>();
  const [webServiceBindingTypesList, setWebServiceBindingTypesList] = useState<any>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const formatMessage = useFormatMessage();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  // Fetch lists
  useEffect(() => {
    fetchAuthenticationTypes().then(ret => setAuthenticationTypesList(ret));
    fetchWebServiceBindingTypes().then(ret => setWebServiceBindingTypesList(ret));
  }, [fetchAuthenticationTypes, fetchWebServiceBindingTypes]);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `WebService has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const webService = {
      ...formData,
      webserviceBindingType: formData.webserviceBindingType
        ? formData.webserviceBindingType.value
        : undefined,
      authenticationType: formData.authenticationType
        ? formData.authenticationType.value
        : undefined,
    };

    delete webService.id;

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this webservice?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Change Web Service",
      }).then(value => {
        if (value) {
          const webServiceToEdit = Object.keys(webService).map(key => {
            return {
              op: "replace",
              path: `/${[key]}`,
              value: webService[key],
            };
          });

          changeWebservice(formData.id, webServiceToEdit).then(res => {
            if (res.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this webservice?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new WebService",
      }).then(value => {
        if (value) {
          createWebservice(webService).then(res => {
            if (res.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={WebServiceSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="webserviceName"
              name="webserviceName"
              label={formatMessage("app.webserviceName", "Webservice Name")}
            />
            <Select
              id="webserviceBindingType"
              name="webserviceBindingType"
              label={formatMessage("app.webserviceBindingType", "Binding Type")}
              options={webServiceBindingTypesList?.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Checkbox
              id="debuggingMode"
              name="debuggingMode"
              label={formatMessage("app.debuggingMode", "Debugging Mode")}
              wrapperProps={{ width: "20%" }}
            />
            <Input
              id="endpointUrl"
              name="endpointUrl"
              label={formatMessage("app.endpointUrl", "Endpoint Url")}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="closeTimeout"
              name="closeTimeout"
              type="number"
              label={formatMessage("app.closeTimeout", "Close Timeout")}
            />
            <Input
              id="receiveTimeout"
              name="receiveTimeout"
              type="number"
              label={formatMessage("app.receiveTimeout", "Receive Timeout")}
            />
            <Input
              id="sendTimeout"
              name="sendTimeout"
              type="number"
              label={formatMessage("app.sendTimeout", "Send Timeout")}
            />
            <Input
              id="openTimeout"
              name="openTimeout"
              type="number"
              label={formatMessage("app.openTimeout", "Open Timeout")}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="maxBufferPoolSize"
              name="maxBufferPoolSize"
              type="number"
              label={formatMessage("app.maxBufferPoolSize", "Max Buffer Pool Size")}
            />
            <Input
              id="maxReceivedMessageSize"
              name="maxReceivedMessageSize"
              type="number"
              label={formatMessage("app.maxReceivedMessageSize", "Max Received Message Size")}
            />
            <Input
              id="maxStringContentLength"
              name="maxStringContentLength"
              type="number"
              label={formatMessage("app.maxStringContentLength", "Max String Content Length")}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="maxArrayLength"
              name="maxArrayLength"
              type="number"
              label={formatMessage("app.maxArrayLength", "Max Array Length")}
            />
            <Input
              id="maxBytesPerRead"
              name="maxBytesPerRead"
              type="number"
              label={formatMessage("app.maxBytesPerRead", "Max Bytes Per Read")}
            />
            <Input
              id="maxDepth"
              name="maxDepth"
              type="number"
              label={formatMessage("app.maxDepth", "Max Depth")}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="authenticationType"
              name="authenticationType"
              label={formatMessage("app.authenticationType", "Binding Type")}
              options={authenticationTypesList?.map(option => ({
                value: option.id,
                label: option.description,
              }))}
            />
            <Input
              id="proxyAddress"
              name="proxyAddress"
              type="string"
              label={formatMessage("app.proxyAddress", "Proxy Address")}
            />
            <Checkbox
              id="useDefaultProxy"
              name="useDefaultProxy"
              label={formatMessage("app.useDefaultProxy", "Use Default Proxy")}
              wrapperProps={{ width: "47%" }}
            />
            <Checkbox
              id="bypassProxyOnlocal"
              name="bypassProxyOnlocal"
              label={formatMessage("app.bypassProxyOnLocal", "Bypass Proxy On Local")}
              wrapperProps={{ width: "47%" }}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="username"
              name="username"
              type="string"
              width={isEditing ? "282px" : null}
              label={formatMessage("app.username", "Username")}
            />
            {!isEditing && (
              <>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label={formatMessage("app.password", "Password")}
                />
                <Input
                  id="passwordrepeat"
                  name="passwordrepeat"
                  type="password"
                  label={formatMessage("app.repeatPassword", "Repeat Password")}
                />
              </>
            )}
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

function mapDispatchToProps(dispatch: Dispatch<ACTION>) {
  return {
    fetchAuthenticationTypes: bindActionCreators(fetchAuthenticationTypes, dispatch),
    fetchWebServiceBindingTypes: bindActionCreators(fetchWebServiceBindingTypes, dispatch),
  };
}

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(WebServicesModal);

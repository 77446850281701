import React from "react";
import { Cookies } from "react-cookie";
import swal from "@sweetalert/with-react";
import { toastr } from "react-redux-toastr";
import axios, { AxiosRequestConfig } from "axios";

import { logOut } from "../shared/state/AuthenticateActions";
import { TResponse } from "./types";

const apiUrl = process.env.REACT_APP_REST_API_URL;
const cookies = new Cookies();

const treatSwalMessage = (message: string): void => {
  swal({
    buttons: {
      ok: {
        text: "Ok",
        className: "modal-main-button primary",
      },
    },
    className: "overflow-auto",
    height: "100%",
    width: "100%",
    icon: "error",
    closeOnEsc: true,
    title: "Some errors have occurred",
    text: message,
  });
};

const handleRequestErrors = errors => {
  if (errors && errors?.length > 0) {
    let swalMessage = "";

    errors.forEach(error => {
      if (error.code === "403" || error.code === "401" || error.code === "4") {
        logOut();
      }
      if (errors.length === 1) {
        toastr.error(error.title, {
          timeout: 0,
          closeOnToastrClick: true,
          component: (
            <div id="detailErrorOnToastr" className="rtt-text">
              {error.detail} ({error.code})
            </div>
          ),
        });
      } else {
        swalMessage += `- ${
          error.detail.length > 30 ? error.detail.slice(0, 30) + "..." : error.detail
        } (${error.code})\n`;
      }
    });

    if (errors.length > 1) {
      treatSwalMessage(swalMessage);
    }
  }
};

const instance = axios.create({
  baseURL: apiUrl,
});

instance.defaults.headers.get["Content-Type"] = "application/json";
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.put["Content-Type"] = "application/json";
instance.defaults.headers.patch["Content-Type"] = "application/json";
instance.defaults.headers.delete["Content-Type"] = "application/json";

instance.interceptors.request.use(function (config) {
  const apiToken = cookies.get("bearerToken");

  if (apiToken) {
    config.headers.common.Authorization = `Bearer ${apiToken}`;
  }

  return config;
});

instance.interceptors.response.use(
  function (response) {
    const { data } = response;
    const { value } = data;

    if (value) {
      const { errors } = value;

      if (errors && errors?.length > 0) {
        response.data.success = false;
      }

      handleRequestErrors(errors);
    }

    return data;
  },
  function (error) {
    const { status, response } = error;

    if (status === 403 || status === 401) {
      logOut();
    }

    handleRequestErrors(response?.data?.errors);

    return Promise.reject(error);
  }
);

const onSuccess = req => ({
  data: req?.response?.data || req?.data || req,
  success: true,
});

const onError = () => ({ data: null, success: false });

const post = (
  url: string,
  data?: Record<string, unknown> | FormData,
  config?: AxiosRequestConfig
): Promise<TResponse> => instance.post(url, data, config).then(onSuccess).catch(onError);

const put = (
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
): Promise<TResponse> => instance.put(url, data, config).then(onSuccess).catch(onError);

const patch = (
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
): Promise<TResponse> => instance.patch(url, data, config).then(onSuccess).catch(onError);

const get = (url: string, config?: AxiosRequestConfig): Promise<TResponse> =>
  instance.get(url, config).then(onSuccess).catch(onError);

const httpDelete = (url: string, config?: AxiosRequestConfig): Promise<TResponse> =>
  instance.delete(url, config).then(onSuccess).catch(onError);

export default { post, put, patch, get, delete: httpDelete };

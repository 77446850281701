import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { FormattedMessage, injectIntl } from "react-intl";

import { Form } from "@components/Form";
import { Button } from "@components/Button";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { Button as OldButton, EButtonType } from "@components/OldButton";

import { TProps } from "./types";
import { CompanySchema } from "./schema";
import { fetchCompanies } from "../../../actions/company";
import { changeCompany, createCompany } from "../ducks";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { bindActionCreators, compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { ACTION } from "shared/models/action";
import { useSwal } from "@hooks/useSwal";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const CompaniesModal: FC<TProps> = props => {
  const { closeModal, initialData, fetchCompanies } = props;
  // const dispatch = useDispatch();
  // Swal
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [isEditing, setIsEditing] = useState<boolean>();
  const [parentCompanyList, setParentCompanyList] = useState<Record<string, unknown>[]>(undefined);

  useEffect(() => {
    setIsEditing(!!initialData.id);
    setParentCompanyList(prev =>
      initialData.id && prev ? prev.filter(data => data.id !== initialData.id) : prev
    );
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    fetchCompanies().then(res => {
      setParentCompanyList(
        res.map(option => ({
          value: option.id,
          label: option.companyName,
        }))
      );
    });
  }, [fetchCompanies]);

  const successfulMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: "Company has been " + message + "!",
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const companyMapping = {
      ...formData,
      parentCompanyId: formData.parentCompanyId ? formData.parentCompanyId.value : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to edit this company?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save changes",
      }).then(value => {
        if (value) {
          changeCompany(formData.id, companyMapping).then(ret => {
            if (ret.success) successfulMessage("updated");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this company?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Company",
      }).then(value => {
        if (value) {
          delete companyMapping.id;
          createCompany(companyMapping).then(res => {
            if (res.success) successfulMessage("inserted");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            <FormattedMessage id="app.backToOverview" defaultMessage="Zurück zur Übersicht" />
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={CompanySchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="companyName"
              name="companyName"
              label={<FormattedMessage id="app.companyName" defaultMessage="Company Name" />}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="parentCompanyId"
              name="parentCompanyId"
              label={<FormattedMessage id="app.parentCompanyId" defaultMessage="Parent Company" />}
              options={parentCompanyList}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              <FormattedMessage id="app.cancel" defaultMessage="Cancel" />
            </Button>
            <Button id="ModalBtn_save" type="submit">
              <FormattedMessage id="app.save" defaultMessage="Save" />
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

function mapDispatchToProps(dispatch: Dispatch<ACTION>) {
  return {
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
  };
}

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(CompaniesModal);

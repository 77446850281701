import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { adjustDotNetDate } from "../../../utils/date";
import {
  setUsersPageNo,
  // setUsersOrderCriteria,
  // setUsersFilterCriteria,
  fetchUsersList,
  deleteUser,
  getUserById,
  incrementUsersPageNo,
} from "./ducks";
import UsersModal from "./Modal/UsersModal";
import { TDispatch, TRootState } from "../../../redux/types";
import { deserialize } from "../../../api/jsonApiParser";
import UsersModalPassword from "./ModalPassword/UsersModalPassword";
import { getParameterByName } from "../../../utils/toSearchParams";

import { ReactComponent as IconEdit } from "@assets/icons/edit.svg";
import { ReactComponent as IconKey } from "@assets/icons/key.svg";
import { ReactComponent as IconTrash } from "@assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";

import Drawer from "@components/Drawer";
import { OldInput } from "@components/OldInput";
import { Text } from "@components/Text";
import ExpandableRow from "@components/ExpandableRow";
import { Button } from "@components/Button";

import "../../../styles/index.scss";
import "./Users.scss";
import { Flex } from "reflexbox";
import { AddButton } from "@components/AddButton";
import { ScrollableList } from "@components/ScrollableList";
import { Column } from "@components/ExpandableRow/styles";
import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";

enum formMode {
  edit = "edit",
  password = "password",
}

const Users: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addUserForm, setaddUserForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);
  const [userFilter, setUserFilter] = useState({
    GenericText: null,
    Username: null,
    FirstName: null,
    LastName: null,
  });

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { usersList, moreUsers, usersPageNo } = useSelector(
    ({ UsersReducer: { users, moreUsers, usersPageNo } }: TRootState) => ({
      usersList: users,
      moreUsers,
      usersPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchUsersList()).then(() => dispatch(incrementUsersPageNo()));
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreUsers) {
      setShowMoreSpinner(true);
      dispatch(fetchUsersList(usersPageNo, 10, userFilter)).then(() => {
        setShowMoreSpinner(false);
        dispatch(incrementUsersPageNo());
      });
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prevState => !prevState);
  };

  const filterUsers = () => {
    dispatch(setUsersPageNo(1));
    dispatch(fetchUsersList(1, 10, userFilter));
  };

  const clearFilters = () => {
    setUserFilter({
      GenericText: null,
      Username: null,
      FirstName: null,
      LastName: null,
    });

    dispatch(setUsersPageNo(1));
    dispatch(fetchUsersList(1, 10));
  };

  const openModal = (content, message?, entry?) => {
    if (content === formMode.edit) {
      getUserById(entry.id).then(ret => {
        if (ret) {
          let user = deserialize(ret.data);
          user.roles = entry.roles;
          // Adjust  date
          user.birthDate = adjustDotNetDate(user.birthDate);
          // Adjust user roles
          // Build roles to show on form if user have any roles
          if (user?.roles && user?.roles.length > 0) {
            user.roles.forEach(userRole => {
              user = {
                ...user,
                [userRole.application +
                userRole.applicationName.charAt(0).toLowerCase() +
                userRole.applicationName.slice(1)]: {
                  label: userRole.applicationName,
                  value: userRole.roleId,
                },
              };
            });
          }
          setaddUserForm(user);
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      setaddUserForm({ id: entry.id });
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterUsers = () => {
    history.push({
      search: "",
    });
    filterUsers();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterUsers();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `User has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this user?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove user",
    }).then(value => {
      if (value) {
        deleteUser(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterUsers);
        });
      }
    });
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && modalContent !== formMode.password ? (
          <UsersModal closeModal={closeModal} initialData={addUserForm} />
        ) : (
          <UsersModalPassword closeModal={closeModal} initialData={addUserForm} />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addUserBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewuser", "Add User")}
            onClick={() => {
              setaddUserForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={usersList}
            onLoadMore={handleLoadMore}
            renderHeader={
              <>
                <Column>
                  <Text>{formatMessage("app.username", "Username")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.prename", "First name")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.lastname", "Last name")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.company", "Company")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.language", "Language")}</Text>
                </Column>
                <Column icon>
                  <Text>
                    <div
                      id="toggleFilterArea"
                      className="icon-filter"
                      tabIndex={0}
                      onClick={e => {
                        e.stopPropagation();
                        changeFilterOpened();
                      }}
                    >
                      <IconFilter />
                    </div>
                  </Text>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                data-cy="userRow"
                key={"userRow" + index}
                id={item.id as string}
                translucent={false}
                expandable={false}
                top={
                  <React.Fragment>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.username}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.firstName}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.lastName}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.companyName}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.languageName}
                        </Text>
                      </div>
                    </Column>
                    <Column icon>
                      <div className="d-flex flex-row showChildOnHover">
                        <div
                          data-cy="changeEntry"
                          className="iconWrapper pointer large"
                          title="Edit User"
                          style={{}}
                          onClick={() => openModal(formMode.edit, "", item)}
                        >
                          <IconEdit />
                        </div>
                        <div
                          data-cy="changePasswordEntry"
                          className="iconWrapper pointer large"
                          title="Edit Password"
                          style={{}}
                          onClick={() => openModal(formMode.password, "", item)}
                        >
                          <IconKey />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete User"
                          style={{}}
                          onClick={() => remove(item.id)}
                        >
                          <IconTrash />
                        </div>
                      </div>
                    </Column>
                  </React.Fragment>
                }
                more={
                  <React.Fragment>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.languageName}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.email}
                        </Text>
                      </div>
                    </Column>
                  </React.Fragment>
                }
              />
            )}
          />
        </Flex>
        <Drawer id="filterArea" opened={filtersOpened}>
          <div className="paddingContainer">
            <p className="colorGrey mb-1">Dynamic Filter</p>
            <div data-cy="dynamicFilterByField">
              <OldInput
                id="dynamicSearch"
                name="dynamicSearch"
                key="dynamicSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.anyfield", "Any Field")}
                value={userFilter.GenericText}
                onChange={(name, value) =>
                  setUserFilter(prevState => ({ ...prevState, GenericText: value }))
                }
                validationMessageConfig={null /* this.validationMessageConfig() */}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>

            <div className="breaker mb-5" />

            <div data-cy="filterByUsername">
              <OldInput
                id="usernameSearch"
                name="usernameSearch"
                key="usernameSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.username", "Username")}
                value={userFilter.Username}
                onChange={(name, value) =>
                  setUserFilter(prevState => ({ ...prevState, usernameFilter: value }))
                }
                validationMessageConfig={undefined}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>
            <div data-cy="filterByFirstname">
              <OldInput
                id="firstnameSearch"
                name="firstnameSearch"
                key="firstnameSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.firstname", "Firstname")}
                value={userFilter.FirstName}
                onChange={(name, value) =>
                  setUserFilter(prevState => ({ ...prevState, firstnameFilter: value }))
                }
                validationMessageConfig={undefined}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>
            <div data-cy="filterByLastname">
              <OldInput
                id="lastnameSearch"
                name="lastnameSearch"
                key="lastnameSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.lastname", "Lastname")}
                value={userFilter.LastName}
                onChange={(name, value) =>
                  setUserFilter(prevState => ({ ...prevState, lastnameFilter: value }))
                }
                validationMessageConfig={undefined}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>
            {/* <div data-cy="filterByCompany">
              <OldInput
                id="companySearch"
                name="companySearch"
                key="companySearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.company", "Company")}
                value={userFilter.company}
                onChange={(name, value) =>
                  setUserFilter(prevState => ({ ...prevState, companyFilter: value }))
                }
                validationMessageConfig={undefined}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>
            <div data-cy="filterByLanguage">
              <OldInput
                id="languageSearch"
                name="languageSearch"
                key="languageSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.language", "Language")}
                value={userFilter.language}
                onChange={(name, value) =>
                  setUserFilter(prevState => ({ ...prevState, languageFilter: value }))
                }
                validationMessageConfig={undefined}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div> */}

            <div className="modal-buttons">
              <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
                {formatMessage("app.clear", "Clear")}
              </Button>

              <Button id="buttonSearch" textButton mr="10px" onClick={filterUsers}>
                {formatMessage("app.search", "Search")}
              </Button>
            </div>
          </div>
        </Drawer>
      </Flex>
    </>
  );
};

export default Users;

import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Flex } from "reflexbox";

import { useFormatMessage } from "@hooks/formatMessage";

import Drawer from "@components/Drawer";
import { Text } from "@components/Text";
import { Column } from "@components/ExpandableRow/styles";
import { Checkbox } from "@components/Checkbox";
import ExpandableRow from "@components/ExpandableRow";
import { Button } from "@components/Button";
import { OldInput } from "@components/OldInput";
import { AddButton } from "@components/AddButton";
import { ScrollableList } from "@components/ScrollableList";
import { useSwal } from "@hooks/useSwal";

import { ReactComponent as IconKey } from "@assets/icons/key.svg";
import { ReactComponent as IconEdit } from "@assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "@assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";

import WebservicesModal from "./Modal/WebServicesModal";
import { deserialize } from "../../../api/jsonApiParser";
import { TDispatch, TRootState } from "../../../redux/types";
import { getParameterByName } from "../../../utils/toSearchParams";
import WebServicesModalPassword from "./ModalPassword/WebServicesModalPassword";
import {
  setWebservicesPageNo,
  fetchWebServices,
  deleteWebservice,
  getWebserviceById,
  incrementWebServicesPageNo,
} from "./ducks";

import "../../../styles/index.scss";
import "./WebServices.scss";

enum formMode {
  edit = "edit",
  password = "password",
}

const WebServices: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addWebserviceForm, setAddWebserviceForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);
  const [webserviceListFilter, setWebserviceListFilter] = useState({
    GenericText: null,
  });

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();

  const dispatch: TDispatch = useDispatch();
  const { webServicesList, moreWebServices, webServicesPageNo } = useSelector(
    ({ WebServicesReducer: { webServices, moreWebServices, webServicesPageNo } }: TRootState) => ({
      webServicesList: webServices,
      moreWebServices,
      webServicesPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchWebServices()).then(() => dispatch(incrementWebServicesPageNo()));
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreWebServices) {
      setShowMoreSpinner(true);
      dispatch(fetchWebServices(webServicesPageNo, 10, webserviceListFilter)).then(() => {
        setShowMoreSpinner(false);
        dispatch(incrementWebServicesPageNo());
      });
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prevState => !prevState);
  };

  const filterWebServices = () => {
    dispatch(setWebservicesPageNo(1));
    dispatch(fetchWebServices(1, 10, webserviceListFilter));
  };

  const clearFilters = () => {
    setWebserviceListFilter({
      GenericText: null,
    });

    dispatch(setWebservicesPageNo(1));
    dispatch(fetchWebServices(1, 10));
  };

  const openModal = (content, message?, entry?) => {
    if (content !== formMode.password.toString()) {
      getWebserviceById(entry.id).then(res => {
        if (res.success) {
          setAddWebserviceForm(deserialize(res.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      setAddWebserviceForm(entry);
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterWebServices = () => {
    history.push({
      search: "",
    });
    filterWebServices();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterWebServices();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `WebService has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this webservice?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove webservice",
    }).then(value => {
      if (value) {
        deleteWebservice(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterWebServices);
        });
      }
    });
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && modalContent !== formMode.password ? (
          <WebservicesModal closeModal={closeModal} initialData={addWebserviceForm} />
        ) : (
          <WebServicesModalPassword closeModal={closeModal} initialData={addWebserviceForm} />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addWebServiceBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewwebservice", "Add WebService")}
            onClick={() => {
              setAddWebserviceForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={webServicesList}
            onLoadMore={handleLoadMore}
            renderHeader={
              <>
                <Column>
                  <Text>{formatMessage("app.webserviceName", "Name")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.endpointUrl", "Endpoint Url")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.username", "Username")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.webserviceBindingTypeName", "Binding Type")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.authenticationTypeName", "Authentication Type")}</Text>
                </Column>
                <Column icon>
                  <Text>
                    <div
                      id="toggleFilterArea"
                      className="icon-filter"
                      tabIndex={0}
                      onClick={e => {
                        e.stopPropagation();
                        changeFilterOpened();
                      }}
                    >
                      <IconFilter />
                    </div>
                  </Text>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                data-cy="webServiceRow"
                key={"webServiceRow" + index}
                id={item.id as string}
                translucent={false}
                expandable={false}
                top={
                  <React.Fragment>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.webserviceName}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.endpointUrl}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.username}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.webserviceBindingTypeName}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.authenticationTypeName}
                        </Text>
                      </div>
                    </Column>
                    <Column icon>
                      <div className="d-flex flex-row showChildOnHover">
                        <div
                          data-cy="changeEntry"
                          className="iconWrapper pointer large"
                          title="Edit Webservice"
                          style={{}}
                          onClick={() => openModal(formMode.edit, "", item)}
                        >
                          <IconEdit />
                        </div>
                        <div
                          data-cy="changePasswordEntry"
                          className="iconWrapper pointer large"
                          title="Edit Password"
                          style={{}}
                          onClick={() => openModal(formMode.password, "", item)}
                        >
                          <IconKey />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete Webservice"
                          style={{}}
                          onClick={() => remove(item.id)}
                        >
                          <IconTrash />
                        </div>
                      </div>
                    </Column>
                  </React.Fragment>
                }
                more={
                  <React.Fragment>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Checkbox
                          id="TableDebuggingModeInput"
                          name="TableDebuggingModeInput"
                          readOnly={true}
                          value={(item.debuggingMode as string) || ""}
                          checked={item.debuggingMode as boolean}
                          label={formatMessage("app.debuggingMode", "Debugging Mode")}
                          wrapperProps={{ width: "20%" }}
                        />
                      </div>
                    </Column>
                  </React.Fragment>
                }
              />
            )}
          />
        </Flex>
        <Drawer id="filterArea" opened={filtersOpened}>
          <div className="paddingContainer">
            <p className="colorGrey mb-1">Dynamic Filter</p>
            <div data-cy="dynamicFilterByField">
              <OldInput
                id="dynamicSearch"
                name="dynamicSearch"
                key="dynamicSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.anyfield", "Any Field")}
                value={webserviceListFilter.GenericText}
                onChange={(name, value) =>
                  setWebserviceListFilter(prevState => ({ ...prevState, GenericText: value }))
                }
                validationMessageConfig={null /* this.validationMessageConfig() */}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>

            <div className="breaker mb-5" />

            <div className="modal-buttons">
              <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
                {formatMessage("app.clear", "Clear")}
              </Button>

              <Button id="buttonSearch" textButton mr="10px" onClick={filterWebServices}>
                {formatMessage("app.search", "Search")}
              </Button>
            </div>
          </div>
        </Drawer>
      </Flex>
    </>
  );
};

export default WebServices;

import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Flex } from "reflexbox";
import { TDispatch, TRootState } from "../../../redux/types";
import { useHistory, useLocation } from "react-router-dom";

import Drawer from "@components/Drawer";
import { OldInput } from "@components/OldInput";
import { AddButton } from "@components/AddButton";
import { ScrollableList } from "@components/ScrollableList";
import { Column } from "@components/ExpandableRow/styles";
import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import ExpandableRow from "@components/ExpandableRow";
import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";
import { Text } from "@components/Text";

import { deserialize } from "../../../api/jsonApiParser";
import { getParameterByName } from "../../../utils/toSearchParams";
import { ReactComponent as IconKey } from "../../../assets/icons/key.svg";
import { ReactComponent as IconEdit } from "../../../assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "../../../assets/icons/filter.svg";
import {
  deleteMailServerSetting,
  setMailServerSettingsPageNo,
  fetchMailServerSettingsList,
  incrementMailServerSettingsPageNo,
  getMailServerSettingById,
} from "./ducks";

import MailServerSettingsModalPassword from "./ModalPassword/MailServerSettingsModalPassword";
import MailServerSettingsModal from "./Modal/MailServerSettingsModal";
import "../../../styles/index.scss";
import "./MailServerSettings.scss";

enum formMode {
  edit = "edit",
  password = "password",
}

const MailServerSettings: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addMailServerSettingForm, setaddMailServerSettingForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);
  const [mailServerSettingsFilter, setMailServerSettingsFilter] = useState({
    GenericText: null,
    IsActive: null,
  });

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { mailServerSettingsList, moreMailServerSettings, mailServerSettingsPageNo } = useSelector(
    ({
      MailServerSettingsReducer: {
        mailServerSettings,
        moreMailServerSettings,
        mailServerSettingsPageNo,
      },
    }: TRootState) => ({
      mailServerSettingsList: mailServerSettings,
      moreMailServerSettings,
      mailServerSettingsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchMailServerSettingsList()).then(() =>
      dispatch(incrementMailServerSettingsPageNo())
    );
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreMailServerSettings) {
      setShowMoreSpinner(true);
      dispatch(
        fetchMailServerSettingsList(mailServerSettingsPageNo, 10, mailServerSettingsFilter)
      ).then(() => {
        setShowMoreSpinner(false);
        dispatch(incrementMailServerSettingsPageNo());
      });
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prevState => !prevState);
  };

  const filterMailServerSettings = () => {
    dispatch(setMailServerSettingsPageNo(1));
    dispatch(fetchMailServerSettingsList(1, 10, mailServerSettingsFilter));
  };

  const clearFilters = () => {
    setMailServerSettingsFilter({
      GenericText: null,
      IsActive: null,
    });

    dispatch(setMailServerSettingsPageNo(1));
    dispatch(fetchMailServerSettingsList(1, 10));
  };

  const openModal = (content, message?, entry?) => {
    if (content === formMode.edit) {
      getMailServerSettingById(entry.id).then(ret => {
        if (ret) {
          setaddMailServerSettingForm(deserialize(ret.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      setaddMailServerSettingForm({ id: entry.id });
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterMailServerSettings = () => {
    history.push({
      search: "",
    });
    filterMailServerSettings();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterMailServerSettings();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `Mail Server Setting has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this mail server setting?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove mail server setting",
    }).then(value => {
      if (value) {
        deleteMailServerSetting(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterMailServerSettings);
        });
      }
    });
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && modalContent !== formMode.password ? (
          <MailServerSettingsModal closeModal={closeModal} initialData={addMailServerSettingForm} />
        ) : (
          <MailServerSettingsModalPassword
            closeModal={closeModal}
            initialData={addMailServerSettingForm}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addMailServerSettingBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewmailserversetting", "Add MailServer Setting")}
            onClick={() => {
              setModalContent(undefined);
              setaddMailServerSettingForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={mailServerSettingsList}
            onLoadMore={handleLoadMore}
            renderHeader={
              <>
                <Column>
                  <Text>{formatMessage("app.domain", "Domain")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.email", "E-mail")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.port", "Port")}</Text>
                </Column>
                <Column icon>
                  <Text>
                    <div
                      id="toggleFilterArea"
                      className="icon-filter"
                      tabIndex={0}
                      onClick={e => {
                        e.stopPropagation();
                        changeFilterOpened();
                      }}
                    >
                      <IconFilter />
                    </div>
                  </Text>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                data-cy="mailServerSettingRow"
                key={"mailServerSettingRow" + index}
                id={item.id as string}
                translucent={false}
                expandable={false}
                top={
                  <React.Fragment>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.mailServerFqdn}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.emailFrom}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.port}
                        </Text>
                      </div>
                    </Column>
                    <Column icon>
                      <div className="d-flex flex-row showChildOnHover">
                        <div
                          data-cy="changeEntry"
                          className="iconWrapper pointer large"
                          title="Edit MailServer Setting"
                          style={{}}
                          onClick={() => openModal(formMode.edit, "", item)}
                        >
                          <IconEdit />
                        </div>
                        <div
                          data-cy="changePasswordEntry"
                          className="iconWrapper pointer large"
                          title="Edit Password"
                          style={{}}
                          onClick={() => openModal(formMode.password, "", item)}
                        >
                          <IconKey />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete User"
                          style={{}}
                          onClick={() => remove(item.id)}
                        >
                          <IconTrash />
                        </div>
                      </div>
                    </Column>
                  </React.Fragment>
                }
              />
            )}
          />
        </Flex>
        <Drawer id="filterArea" opened={filtersOpened}>
          <div className="paddingContainer">
            <p className="colorGrey mb-1">Dynamic Filter</p>
            <div data-cy="dynamicFilterByField">
              <OldInput
                id="dynamicSearch"
                name="dynamicSearch"
                key="dynamicSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.anyfield", "Any Field")}
                value={mailServerSettingsFilter.GenericText}
                onChange={(name, value) =>
                  setMailServerSettingsFilter(prevState => ({ ...prevState, GenericText: value }))
                }
                validationMessageConfig={null /* this.validationMessageConfig() */}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>
            <div data-cy="filterByIsActive">
              {/* <Flex> */}
              <Checkbox
                id="isActiveSearch"
                name="isActiveSearch"
                label={formatMessage("app.isactive", "IsActive")}
                checked={mailServerSettingsFilter.IsActive}
                setChecked={value =>
                  setMailServerSettingsFilter(prevState => ({ ...prevState, IsActive: value }))
                }
              />
              {/* </Flex> */}
            </div>
            <div className="breaker mb-5" />
            <div className="modal-buttons">
              <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
                {formatMessage("app.clear", "Clear")}
              </Button>

              <Button id="buttonSearch" textButton mr="10px" onClick={filterMailServerSettings}>
                {formatMessage("app.search", "Search")}
              </Button>
            </div>
          </div>
        </Drawer>
      </Flex>
    </>
  );
};

export default MailServerSettings;

import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";

import { useFormatMessage } from "@hooks/formatMessage";
import { ReactComponent as IconArrowRight } from "@assets/icons/arrow-right.svg";

import { Form } from "@components/Form";
import { Button } from "@components/Button";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { useSwal } from "@hooks/useSwal";

import { TProps } from "./types";
import { TenantsSchema } from "./schema";
import { changeTenant, createTenant } from "../ducks";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const TenantsModal: FC<TProps> = props => {
  const { companyList, closeModal, initialData } = props;
  const [isEditing, setIsEditing] = useState<boolean>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const formatMessage = useFormatMessage();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const tenant = {
      ...formData,
      companyId: formData.companyId ? formData.companyId.value : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this tenant?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Change Tenant",
      }).then(value => {
        if (value) {
          changeTenant(formData.id, tenant).then(res => {
            if (res.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this tenant?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save Tenant",
      }).then(value => {
        if (value) {
          delete tenant.id;
          createTenant(tenant).then(res => {
            if (res.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            {formatMessage("app.backToOverview")}
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={TenantsSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="companyId"
              name="companyId"
              label={formatMessage("app.company")}
              options={companyList.map(option => ({
                value: option.id,
                label: option.companyName,
              }))}
            />
            <Input id="insuranceId" name="insuranceId" label={formatMessage("app.insuranceId")} />
          </Flex>
          <Flex mt="30px">
            <Input id="tenantCode" name="tenantCode" label={formatMessage("app.tenantCode")} />
          </Flex>
          <Flex mt="30px">
            <Input id="tenantName" name="tenantName" label={formatMessage("app.tenantName")} />
          </Flex>
          <Flex mt="30px">
            <Input id="description" name="description" label={formatMessage("app.description")} />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              {formatMessage("app.cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default TenantsModal;

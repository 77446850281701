import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import dateFormat from "dateformat";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Drawer from "@components/Drawer";
import Header from "@components/Header";
import { Text } from "@components/Text";
import { OldInput } from "@components/OldInput";
import ExpandableRow from "@components/ExpandableRow";
import { ListContainer } from "@components/ListContainer";
import { Column } from "@components/ExpandableRow/styles";
import { ScrollableList } from "@components/ScrollableList";
import { Button, EButtonType } from "@components/OldButton";

import { useFormatMessage } from "@hooks/formatMessage";

import "../../styles/index.scss";
import { TDispatch, TRootState } from "../../redux/types";
import { fetchSessionsList, incrementSessionPageNo, setSessionsPageNo } from "./ducks";
import { ReactComponent as IconFilter } from "../../assets/icons/filter.svg";

const Session: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [sessionFilter, setSessionFilter] = useState({
    GenericText: null,
    dynamicFilter: "",
  });

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { sessions, moreSessions } = useSelector(
    ({ SessionReducer: { sessions, sessionsPageNo, moreSessions } }: TRootState) => ({
      sessions,
      sessionsPageNo,
      moreSessions,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchSessionsList()).then(() => dispatch(incrementSessionPageNo()));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreSessions) {
      dispatch(fetchSessionsList()).then(() => dispatch(incrementSessionPageNo()));
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterSessions = () => {
    dispatch(setSessionsPageNo(1));
    dispatch(fetchSessionsList());
  };

  const clearFilters = () => {
    setSessionFilter(prev => ({
      ...prev,
      dynamicFilter: "",
    }));

    dispatch(setSessionsPageNo(1));
    dispatch(fetchSessionsList());
  };

  const renderSessionsSideBar = () => (
    <Drawer id="filterArea" opened={filtersOpened}>
      <div className="paddingContainer">
        <p className="colorGrey mb-1">Dynamic Filter</p>
        <div data-cy="dynamicFilterByField">
          <OldInput
            id="dynamicSearch"
            name="dynamicSearch"
            key="dynamicSearch"
            inputWrapperClass="text-input filter-input"
            placeholder={formatMessage("app.anyfield", "Any Field")}
            value={sessionFilter.dynamicFilter}
            onChange={(name, value) =>
              setSessionFilter(prev => ({
                ...prev,
                dynamicFilter: value,
              }))
            }
            type="text"
            filtering
          />
        </div>

        <div className="breaker mb-5" />

        <div className="modal-buttons">
          <Button
            dataCyName="buttonClearSearch"
            className="modal-sub-button primary"
            onClick={e => {
              e.stopPropagation();
              clearFilters();
            }}
            buttonType={EButtonType.ButtonText}
          >
            <FormattedMessage id="app.clear" defaultMessage="Clear" />
          </Button>
          <Button
            dataCyName="buttonSearch"
            className="modal-main-button primary"
            onClick={e => {
              e.stopPropagation();
              filterSessions();
            }}
            buttonType={EButtonType.Button}
          >
            <FormattedMessage id="app.search" defaultMessage="Search" />
          </Button>
        </div>
      </div>
    </Drawer>
  );

  return (
    <ListContainer>
      <Header title="Sessions" />
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <ScrollableList
          items={sessions}
          renderHeader={
            <>
              <Column size={1}>
                <Text>{formatMessage("app.username")}</Text>
              </Column>
              <Column size={1}>
                <Text>{formatMessage("app.prename")}</Text>
              </Column>
              <Column size={1}>
                <Text>{formatMessage("app.lastname")}</Text>
              </Column>
              <Column size={1}>
                <Text>{formatMessage("app.email")}</Text>
              </Column>
              <Column size={1}>
                <Text>{formatMessage("app.sessionFrom")}</Text>
              </Column>
              <Column size={1}>
                <Text>{formatMessage("app.sessionUntil")}</Text>
              </Column>
              <Column icon>
                <div
                  id="toggleFilterArea"
                  className="icon-filter"
                  tabIndex={0}
                  onClick={e => {
                    e.stopPropagation();
                    changeFilterOpened();
                  }}
                >
                  <IconFilter />
                </div>
              </Column>
            </>
          }
          renderItem={(item, index) => (
            <ExpandableRow
              data-cy="sessionRow"
              key={"sessionRow" + index}
              id={"sessionRow" + index}
              index={index}
              translucent={false}
              expandable={false}
              top={
                <>
                  <Column size={1}>
                    <Text light={false} fontSize="16px" data-cy="firstname">
                      {item.username}
                    </Text>
                  </Column>
                  <Column size={1}>
                    <Text light={false} fontSize="16px" data-cy="lastname">
                      {item.firstName}
                    </Text>
                  </Column>

                  <Column size={1}>
                    <Text light={false} fontSize="16px" data-cy="username">
                      {item.lastName}
                    </Text>
                  </Column>

                  <Column size={1}>
                    <Text light={false} fontSize="16px" data-cy="email">
                      {item.email}
                    </Text>
                  </Column>

                  <Column size={1}>
                    <Text light={false} fontSize="16px" data-cy="startDate">
                      {dateFormat(item.sessionStartDate, "yyyy/mm/dd HH:mm:ss")}
                    </Text>
                  </Column>

                  <Column size={1}>
                    <Text light={false} fontSize="16px" data-cy="expirationDate">
                      {dateFormat(item.sessionExpirationDate, "yyyy/mm/dd HH:mm:ss")}
                    </Text>
                  </Column>
                  <Column icon />
                </>
              }
            />
          )}
          onLoadMore={handleScroll}
        />
        {renderSessionsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default Session;

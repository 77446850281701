import * as yup from "yup";

const UserSchema = yup.object().shape({
  id: yup.string(),
  firstName: yup.string().required(),
  middleName: yup.string().required(),
  lastName: yup.string().required(),
  birthDate: yup.date().required(),
  socialSecurityNumber: yup.string().required(),
  sex: yup.object().required().nullable(),
  completeAddress: yup.string().required(),
  language: yup.object().required().nullable(),
  companyId: yup.object().required().nullable(),
  username: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  email: yup.string().email().required(),
  mobileNumber: yup.string().required(),
  password: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  passwordrepeat: yup.string().when("id", {
    is: val => !val,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "app.passwordsDontMatch")
      .required(),
    otherwise: yup.string(),
  }),
});

export { UserSchema };

import * as constants from "../../constants";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export interface LoginState {
  authentication: any;
  userData: any;
  errors: Array<any>;
}

const getInitialState = () => {
  return {
    authentication: {
      login: null,
      token: cookies.get("bearerToken"),
    },
    userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {},
    errors: [],
  };
};

const login = (state: LoginState = getInitialState(), action: any) => {
  switch (action.type) {
    case constants.LOGIN: {
      // console.log("reducer login start", action.payload);
      return {
        ...state,
        authentication: {
          name: action.payload.login,
          rememberMe: action.payload.rememberMe,
          token: action.payload.token,
          step: action.payload.step,
        },
      };
    }

    case constants.LOGIN_SUCCESS: {
      const payloadData = { ...action.payload.data };
      const userData = {
        firstName: payloadData.firstName,
        lastName: payloadData.lastName,
        email: payloadData.email,
      };
      const newAuthentication = {
        ...state.authentication,
        token: action.payload.token,
        step: action.payload.step,
      };
      window.localStorage.setItem("userData", JSON.stringify(userData));
      return { ...state, authentication: newAuthentication, userData };
    }

    case constants.LOG_OUT: {
      cookies.remove("bearerToken");
      window.localStorage.removeItem("userData");
      // return { ...state, authentication: {login: null, token: null, expirationDate: null}, userData: null };
      return { ...getInitialState() };
    }

    case constants.LOGIN_ERROR: {
      // @TODO better handle state change when error
      return {
        ...state,
        authentication: getInitialState().authentication,
        errors: [action.error],
        userData: null,
      };
    }
  }

  return state;
};

export default login;

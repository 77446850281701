import * as yup from "yup";

const DefaultVariableSchema = yup.object().shape({
  passwordSalt: yup.string().required(),
  secretKeyForOtpNetHotp: yup.string().required(),
  baseUrlPostalLetter: yup.string().required(),
  tokenPostalLetter: yup.string().required(),
  sms_AwsAccessKeyId: yup.string().required(),
  sms_AwsSecretAccessKey: yup.string().required(),
  translation_AwsAccessKeyId: yup.string().required(),
  translation_AwsSecretAccessKey: yup.string().required(),
});

export { DefaultVariableSchema };

import styled from "@emotion/styled";
import { layout, space } from "styled-system";
import { colors } from "@styles/colors";

const StyledAddButton = styled.button`
  ${space}
  ${layout}
  display: flex;
  align-items: center;
  height: 56px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: ${colors.primary};
  transition: all 0.2s linear;

  &:hover {
    opacity: 0.8;
  }
`;

export { StyledAddButton };

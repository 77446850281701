import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { injectIntl } from "react-intl";

import { Form } from "@components/Form";
import { FormInput as Input } from "@components/Form/Input";
import { Button } from "@components/Button";
import { FormSelect as Select } from "@components/Form/Select";
import { InputFile } from "@components/Form/InputFile";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { useSwal } from "@hooks/useSwal";

import { TProps } from "./types";
import { TenantSettingsSchema } from "./schema";
import { uploadFileTenantSetting } from "../ducks";
import { ReactComponent as IconArrowRight } from "../../../../assets/icons/arrow-right.svg";

import { fetchApplications, fetchTenantSettingTypes } from "../../../../actions/enumerator";
import { fetchTenants } from "../../../../actions/tenant";
import { bindActionCreators, compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { ACTION } from "../../../../shared/models/action";
import { useFormatMessage } from "@hooks/formatMessage";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const TenantSettingsModal: FC<TProps> = props => {
  const {
    fetchTenants,
    fetchApplications,
    fetchTenantSettingTypes,
    closeModal,
    initialData,
  } = props;

  // Language
  const formatMessage = useFormatMessage();

  const [tenantsList, setTenants] = useState<any>();
  const [applicationsList, setApplications] = useState<any>();
  const [tenantSettingTypesList, setTenantSettingTypes] = useState<any>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    fetchTenants().then(val => setTenants(val));
    fetchApplications().then(val => setApplications(val));
    fetchTenantSettingTypes().then(val => setTenantSettingTypes(val));
  }, [fetchTenants, fetchApplications, fetchTenantSettingTypes]);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant Setting has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const info = new FormData();
    info.append("files", formData.files[0]);

    swalConfirmationMessage({
      text: "Do you really want to upload this tenant setting?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Upload Setting",
    }).then(value => {
      if (value) {
        uploadFileTenantSetting(
          formData.tenantId ? formData.tenantId.value : undefined,
          formData.applicationCode ? formData.applicationCode.value : undefined,
          formData.type ? formData.type.value : undefined,
          formData.id,
          info
        ).then(ret => {
          if (ret.success) showSuccessMessage("created");
        });
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={TenantSettingsSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="tenantId"
              name="tenantId"
              label={formatMessage("app.tenantId", "Tenant")}
              options={tenantsList?.map(option => ({
                value: option.id,
                label: option.tenantName,
              }))}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="applicationCode"
              name="applicationCode"
              label={formatMessage("app.applicationCode", "Application")}
              options={applicationsList?.map(option => ({
                value: option.id,
                label: option.description,
              }))}
            />
            <Select
              id="type"
              name="type"
              label={formatMessage("app.type", "Type")}
              options={tenantSettingTypesList?.map(option => ({
                value: option.id,
                label: option.description,
              }))}
            />
          </Flex>
          <Flex mt="30px">
            <InputFile
              id="files"
              name="files"
              label={formatMessage("app.files", "File")}
              multiple={true}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

function mapDispatchToProps(dispatch: Dispatch<ACTION>) {
  return {
    fetchTenants: bindActionCreators(fetchTenants, dispatch),
    fetchApplications: bindActionCreators(fetchApplications, dispatch),
    fetchTenantSettingTypes: bindActionCreators(fetchTenantSettingTypes, dispatch),
  };
}

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(TenantSettingsModal);

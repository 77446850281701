import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import Drawer from "@components/Drawer";
import { Text } from "@components/Text";
import { OldInput } from "@components/OldInput";
import { AddButton } from "@components/AddButton";
import ExpandableRow from "@components/ExpandableRow";
import { ListContainer } from "@components/ListContainer";
import { Column } from "@components/ExpandableRow/styles";
import { Button } from "@components/Button";
import { ScrollableList } from "@components/ScrollableList";
import { useSwal } from "@hooks/useSwal";

import { ReactComponent as IconEdit } from "@assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "@assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";

import "../../../styles/index.scss";
import { TDispatch, TRootState } from "../../../redux/types";
import TenantSettingsModal from "./Modal/TenantSettingModal";
import { getParameterByName } from "../../../utils/toSearchParams";
import {
  setTenantSettingsPageNo,
  fetchTenantSettingList,
  deleteTenantSetting,
  getTenantSettingById,
  incrementTenantSettingPageNo,
} from "./ducks";

enum formMode {
  edit = "edit",
}

const TenantSettings: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTenantSettingForm, setAddTenantSettingForm] = useState(undefined);
  const [tenantSettingFilter, setTenantSettingFilter] = useState({
    GenericText: null,
    dynamicFilter: "",
  });
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const location = useLocation();
  const history = useHistory();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { tenantSettings, moreTenantSettings, tenantSettingsPageNo } = useSelector(
    ({
      TenantSettingsReducer: { tenantSettings, moreTenantSettings, tenantSettingsPageNo },
    }: TRootState) => ({
      tenantSettings,
      moreTenantSettings,
      tenantSettingsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTenantSettingList()).then(() => incrementTenantSettingPageNo());
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTenantSettings) {
      dispatch(fetchTenantSettingList(tenantSettingsPageNo, 10, tenantSettingFilter)).then(() =>
        dispatch(incrementTenantSettingPageNo())
      );
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterTenantSettings = () => {
    dispatch(setTenantSettingsPageNo(1));
    dispatch(fetchTenantSettingList(1, 10, tenantSettingFilter));
  };

  const clearFilters = () => {
    setTenantSettingFilter(prev => ({
      ...prev,
      dynamicFilter: "",
    }));

    dispatch(setTenantSettingsPageNo(1));
    dispatch(fetchTenantSettingList());
  };

  const renderTenantSettingsSideBar = () => (
    <Drawer id="filterArea" opened={filtersOpened}>
      <div className="paddingContainer">
        <p className="colorGrey mb-1">Dynamic Filter</p>
        <div data-cy="dynamicFilterByField">
          <OldInput
            id="dynamicSearch"
            name="dynamicSearch"
            key="dynamicSearch"
            inputWrapperClass="text-input filter-input"
            placeholder={formatMessage("app.anyfield", "Any Field")}
            value={tenantSettingFilter.dynamicFilter}
            onChange={(name, value) => {
              setTenantSettingFilter(prev => ({
                ...prev,
                dynamicFilter: value,
              }));
            }}
            type="text"
            filtering
          />
        </div>
        <div className="breaker mb-5" />

        <div className="modal-buttons">
          <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
            {formatMessage("app.clear", "Clear")}
          </Button>

          <Button id="buttonSearch" textButton mr="10px" onClick={filterTenantSettings}>
            {formatMessage("app.search", "Search")}
          </Button>
        </div>
      </div>
    </Drawer>
  );

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterTenantSettings();
  };

  const openModal = (content, message?, tenantSetting?) => {
    getTenantSettingById(tenantSetting.id).then(res => {
      if (res.success) {
        setAddTenantSettingForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const saveHistoryAndFilterTenantSettings = () => {
    history.push({
      search: "",
    });
    filterTenantSettings();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant Setting has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterTenantSettings
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this tenant setting?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove tenant setting",
    }).then(value => {
      if (value) {
        deleteTenantSetting(id).then(res => {
          if (res.success) showSuccessMessage("deleted");
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {modalIsOpen && (
          <TenantSettingsModal closeModal={closeModal} initialData={addTenantSettingForm} />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addTenantSettingBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addSetting")}
            onClick={() => {
              setAddTenantSettingForm({});
              setModalIsOpen(true);
            }}
          />

          <ScrollableList
            items={tenantSettings}
            renderHeader={
              <>
                <Column>
                  <Text>{formatMessage("tenant")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.application")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.type")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.file")}</Text>
                </Column>
                <Column icon>
                  <div
                    id="toggleFilterArea"
                    className="icon-filter"
                    tabIndex={0}
                    onClick={e => {
                      e.stopPropagation();
                      changeFilterOpened();
                    }}
                  >
                    <IconFilter />
                  </div>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                data-cy="tenantSettingRow"
                key={"tenantSettingRow" + index}
                id={"tenantSettingRow" + index}
                index={index}
                translucent={false}
                expandable={false}
                top={
                  <>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="tenantName">
                        {item.tenantName}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="applicationName">
                        {item.applicationName}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="typeName">
                        {item.typeName}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="fileName">
                        {item.fileName}
                      </Text>
                    </Column>
                    <Column icon>
                      <div
                        data-cy="changeEntry"
                        className="iconWrapper pointer large"
                        title="Edit Setting"
                        onClick={() => openModal(formMode.edit, "", item)}
                      >
                        <IconEdit />
                      </div>
                      <div
                        data-cy="deleteEntry"
                        className="iconWrapper pointer large"
                        title="Delete Setting"
                        onClick={() => remove(item.id)}
                      >
                        <IconTrash />
                      </div>
                    </Column>
                  </>
                }
              />
            )}
            onLoadMore={handleScroll}
          />
        </Flex>
        {renderTenantSettingsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default TenantSettings;

export enum orderToggleEnum {
  Off = "",
  Ascending = " asc",
  Descending = " desc",
}

export const toggleOrder = (order: orderToggleEnum) => {
  switch (order) {
    case orderToggleEnum.Off:
      return orderToggleEnum.Ascending;
    case orderToggleEnum.Ascending:
      return orderToggleEnum.Descending;
    case orderToggleEnum.Descending:
      return orderToggleEnum.Off;
  }
};

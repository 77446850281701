import apiCaller from "../api/apiHandler";
import * as constants from "../constants";
import requestWraper from "../api/requestWraper";
import { deserialize } from "../api/jsonApiParser";

export const fetchObjects = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Object/`).then((response: any) => {
        return response.data.map(user => deserialize(user));
      });
      dispatch({ type: constants.LOADING, payload: false });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Something went wrong!";
    }
    return true;
  });

import React, { FC, useEffect } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";

import { Form } from "@components/Form";
import { Button } from "@components/Button";
import { useFormatMessage } from "@hooks/formatMessage";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { InputFile } from "@components/Form/InputFile";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { ReactComponent as IconArrowRight } from "@assets/icons/arrow-right.svg";

import { TProps } from "./types";
import { FilesSchema } from "./schema";
import { uploadFile } from "../ducks";
import { useSwal } from "@hooks/useSwal";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const FilesModal: FC<TProps> = props => {
  const { folderList, closeModal, initialData } = props;
  // Swal
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const formatMessage = useFormatMessage();

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const successfulMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: "File has been " + message + "!",
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const info = new FormData();
    info.append("files", formData.files[0]);

    // Confirm action
    swalConfirmationMessage({
      text: "Do you really want to upload this file?",
      // content: (),
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Save changes",
    }).then(value => {
      if (value) {
        uploadFile(formData.folderId ? formData.folderId.value : undefined, formData.id, info).then(
          ret => {
            if (ret.success) successfulMessage("created");
          }
        );
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={FilesSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="folderId"
              name="folderId"
              label={formatMessage("app.folderId", "Folder")}
              options={folderList.map(option => {
                return {
                  value: option.id,
                  label: option.folderName,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <InputFile
              id="files"
              name="files"
              label={formatMessage("app.files", "Files")}
              multiple={true}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default FilesModal;

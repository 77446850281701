/**
 * Converts C# string date to javascript date(input)
 * @param date string date from API
 */
export function adjustDotNetDate(date: string) {
  const jsDate = new Date(date);
  const day = jsDate.getDate();
  const month = jsDate.getMonth() + 1;
  const year = jsDate.getFullYear();

  return `${year}${month < 10 ? "-0" : "-"}${month}-${day}`;
}

import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import { useFormatMessage } from "@hooks/formatMessage";

import { Form } from "@components/Form";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import { InputFile } from "@components/Form/InputFile";
import { ListContainer } from "@components/ListContainer";
import { FormSelect as Select } from "@components/Form/Select";

import { TProps } from "./types";
import { importDatabase } from "./ducks";
import { DatabaseImportSchema } from "./schema";
import { fetchFiles } from "../../../actions/file";
import { TDispatch } from "../../../redux/types";
import { useSwal } from "@hooks/useSwal";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const DatabaseImport: FC<TProps> = props => {
  const { initialData } = props;
  const [fileList, setFileList] = useState([]);
  // Swal
  const { swalAlert, swalConfirmationMessage } = useSwal();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFiles()).then(res => setFileList(res));
  }, [dispatch]);

  const successfulMessage = (message: string) => {
    swalAlert({
      text: `${message}`,
      type: "success",
      confirmationText: "Ok!",
    });
  };

  const onSubmit = formData => {
    const info = new FormData();
    info.append("files", formData.files[0]);

    swalConfirmationMessage({
      text: "Do you really want import database?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Import Database",
    }).then(value => {
      if (value) {
        importDatabase(formData.fileId ? formData.fileId.value : undefined, info).then(res => {
          if (res.success) successfulMessage("Database imported!");
        });
      }
    });
  };

  return (
    <ListContainer>
      <div className={classNames("main-content-container users")}>
        <div className="content-container">
          <Card>
            <Flex flexDirection="column">
              <Form onSubmit={onSubmit} schema={DatabaseImportSchema} defaultValues={initialData}>
                <Flex>
                  <Select
                    id="fileId"
                    name="fileId"
                    label={formatMessage("app.fileIdFromMigration")}
                    options={fileList?.map(option => ({
                      value: option.id,
                      label: option.fileName,
                    }))}
                  />
                </Flex>
                <Flex mt="30px">
                  <InputFile
                    id="files"
                    name="files"
                    label={formatMessage("app.file")}
                    multiple={true}
                  />
                </Flex>
                <Flex mt="15px" ml="15px">
                  <Button id="webServicesModalBtn" type="submit">
                    {formatMessage("app.importDatabase")}
                  </Button>
                </Flex>
              </Form>
            </Flex>
          </Card>
        </div>
      </div>
    </ListContainer>
  );
};

export default DatabaseImport;

import * as constants from "../../../constants";
import { TAction, TDispatch } from "../../../redux/types";

import http from "@api/http";
import { TResponse } from "@api/types";
import { deserialize, serialize } from "@api/jsonApiParser";

export const FETCHED_TENANTTRANSLATIONS = "FETCHED_TENANTTRANSLATIONS";
export const SET_FILTERING_CRITERIA_TENANTTRANSLATIONS =
  "SET_FILTERING_CRITERIA_TENANTTRANSLATIONS";
export const SET_TENANTTRANSLATIONS_PAGE_NO = "SET_TENANTTRANSLATIONS_PAGE_NO";
export const INCREMENT_TENANTTRANSLATIONS_PAGE_NO = "INCREMENT_TENANTTRANSLATIONS_PAGE_NO";

export interface TenantTranslationsState {
  tenantTranslations: any;
  tenantTranslationsPageNo: any;
  moreTenantTranslations: boolean;
  filter: any;
}

const initialState = {
  tenantTranslations: [],
  tenantTranslationsPageNo: 1,
  moreTenantTranslations: true,
  filter: undefined,
};

export const TenantTranslationReducer = (
  state: TenantTranslationsState = initialState,
  action: any
) => {
  switch (action.type) {
    case FETCHED_TENANTTRANSLATIONS: {
      const { payload } = action;
      const { tenantTranslations, page } = payload;

      let newTenantTranslations = [];
      const moreTenantTranslations = !(tenantTranslations.length < constants.PAGINATION_SIZE);

      if (state.tenantTranslationsPageNo === 1 || page === 1) {
        newTenantTranslations = [...tenantTranslations];
      } else {
        newTenantTranslations = [...state.tenantTranslations, ...tenantTranslations];
      }

      return {
        ...state,
        tenantTranslations: newTenantTranslations,
        moreTenantTranslations: moreTenantTranslations,
      };
    }

    case SET_TENANTTRANSLATIONS_PAGE_NO: {
      const moreTenantTranslations = !(
        state.tenantTranslations.length <
        (state.tenantTranslationsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return {
        ...state,
        tenantTranslationsPageNo: action.payload,
        moreTenantTranslations: moreTenantTranslations,
      };
    }

    case SET_FILTERING_CRITERIA_TENANTTRANSLATIONS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_TENANTTRANSLATIONS_PAGE_NO: {
      return {
        ...state,
        tenantTranslationsPageNo: state.tenantTranslationsPageNo + 1,
      };
    }
  }

  return state;
};

export const setTenantTranslationsPageNo = (payload: number): TAction => ({
  type: SET_TENANTTRANSLATIONS_PAGE_NO,
  payload,
});

export const fetchTenantTranslationList = (
  page = 1,
  pageSize = 10,
  filter?: Record<string, unknown>
) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    let query = `TenantTranslation/TenantTranslationPaginated?Page=${page}&PageSize=${pageSize}`;

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (key === "dynamicFilter" && filter[key]) query += `&SearchGeneric=${filter[key]}`;
        else if (key !== "ListNotTranslated" && filter[key]) {
          query += `&Search=${key.replace("Filter", "")} ${
            key !== "TranslationId" && key !== "TextTranslated" ? "eq" : "co"
          } ${filter[key]}`;
        }
      });
    }

    return await http.get(query).then((response: TResponse) => {
      dispatch({
        type: FETCHED_TENANTTRANSLATIONS,
        payload: {
          tenantTranslations: (response.data as Record<string, unknown>[]).map(tenantTranslation =>
            deserialize(tenantTranslation)
          ),
          page,
        },
      });
      dispatch({ type: constants.LOADING, payload: false });
    });
  };
};

export const changeTenantTranslation = (id: string, payload) => {
  return http.put("TenantTranslation/" + id, serialize(payload, "TenantTranslation"));
};

export const getTenantTranslationById = (id: string) => {
  return http.get("TenantTranslation/" + id);
};

export const incrementTenantTranslationPageNo = (): TAction => ({
  type: INCREMENT_TENANTTRANSLATIONS_PAGE_NO,
});

// React
import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, bindActionCreators, Dispatch } from "redux";
// Hooks
import { useFormatMessage } from "../../../../hooks/formatMessage";
import { useSwal } from "../../../../hooks/useSwal";
// Layout/Components
import { Flex } from "reflexbox";
import { Form } from "@components/Form";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { Button } from "@components/Button";
import { ReactComponent as IconArrowRight } from "../../../../assets/icons/arrow-right.svg";
// Models
import { ACTION } from "../../../../shared/models/action";
// Actions
import { TProps } from "./types";
import { UserSchema } from "./schema";
import { createUser, changeUser, updateUserRoles } from "../ducks";
import { fetchLanguages, fetchApplications, fetchGenders } from "../../../../actions/enumerator";
import { fetchRoles } from "../../../../actions/role";
import { fetchCompanies } from "../../../../actions/company";
import { Checkbox } from "@components/Checkbox";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const UsersModal: FC<TProps> = props => {
  // Props
  const {
    closeModal,
    initialData,
    fetchRoles,
    fetchCompanies,
    fetchLanguages,
    fetchApplications,
    fetchGenders,
  } = props;
  // Language
  const formatMessage = useFormatMessage();
  // Swal
  const { swalAlert, swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  // Page control
  const [isEditing, setIsEditing] = useState<boolean>();
  // Lists
  const [rolesList, setRolesList] = useState<any>();
  const [applicationsList, setApplicationsList] = useState<any>();
  const [companiesList, setCompaniesList] = useState<any>();
  const [languagesList, setLanguagesList] = useState<any>();
  const [gendersList, setGendersList] = useState<any>();
  // Manual errors if needed
  const manualErrors = [];

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const buildApplicationIdentifier = application => {
    return application.id + application.name.charAt(0).toLowerCase() + application.name.slice(1);
  };

  // Fetch lists
  useEffect(() => {
    fetchRoles().then(ret => setRolesList(ret));
    fetchApplications().then(ret => setApplicationsList(ret));
    fetchCompanies().then(ret => setCompaniesList(ret));
    fetchLanguages().then(ret => setLanguagesList(ret));
    fetchGenders().then(ret => setGendersList(ret));
  }, [fetchRoles, fetchCompanies, fetchLanguages, fetchApplications, fetchGenders]);

  const buildNewUserRolesList = formData => {
    // Build roles list
    const userRolesList = [];
    // Find user roles on formData
    applicationsList.forEach(application => {
      const applicationIdentifier = buildApplicationIdentifier(application);
      // If user changed role on form, and this changed role is different from old (if have an old)
      if (
        (formData[applicationIdentifier] && !initialData[applicationIdentifier]) ||
        (formData[applicationIdentifier] &&
          initialData[applicationIdentifier] &&
          formData[applicationIdentifier].value === initialData[applicationIdentifier].value)
      ) {
        userRolesList.push({
          roleId: formData[applicationIdentifier].value,
          application: application.id,
          applicationName: application.name,
          userId: formData.id,
        });
      }
      // Removes from formData reference
      delete formData[applicationIdentifier];
    });
    // Return user roles list
    return userRolesList;
  };

  const showSuccessMessage = (username, action) => {
    swalAlertWithCallback(
      {
        text: `User: ${username} has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const editUser = (user, userRoles, userId, haveUserRoles) => {
    // Removes id
    delete user.id;
    // Create object to send to api
    const userToEdit = Object.keys(user).map(key => {
      return {
        op: "replace",
        path: `/${[key]}`,
        value: user[key],
      };
    });
    // Confirm action
    swalConfirmationMessage({
      text: "Do you really want to change this user?",
      // content: (),
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Save changes",
    }).then(value => {
      if (value) {
        // Send to api
        changeUser(userId, userToEdit).then(ret => {
          // If have any role to update
          if (ret.success) {
            if (haveUserRoles) {
              // update roles
              updateUserRoles({ roles: userRoles }).then((ret: any) => {
                if (ret.success) {
                  showSuccessMessage(initialData.username, "changed");
                } else {
                  swalAlert({
                    text: "An Error has occured while setting the roles!",
                    type: "error",
                  });
                }
              });
            } else {
              showSuccessMessage(initialData.username, "changed");
            }
          }
        });
      }
    });
  };

  const newUser = (user, userRoles) => {
    swalConfirmationMessage({
      text: "Do you really want to create this user?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Save new User",
    }).then(value => {
      if (value) {
        // Build user with roles object
        user = {
          ...user,
          roles: userRoles,
        };
        // Send to api
        createUser(user).then(ret => {
          if (ret.success) {
            showSuccessMessage(user.username, "created");
          } else {
            swalAlert({
              text: "An Error has occured while add user!",
              type: "error",
            });
          }
        });
      }
    });
  };

  const onSubmit = formData => {
    // Build user roles list
    const userRoles = buildNewUserRolesList(formData);
    // Validate user roles
    const haveUserRoles = userRoles && userRoles.length > 0;
    // Build user object
    const user = {
      ...formData,
      sex: formData.sex ? formData.sex.value : undefined,
      language: formData.language ? formData.language.value : undefined,
      companyId: formData.companyId ? formData.companyId.value : undefined,
    };

    // Edit user
    if (isEditing) {
      editUser(user, userRoles, formData.id, haveUserRoles);
    } else if (haveUserRoles && !isEditing) {
      // Create user
      newUser(user, userRoles);
    } else {
      swalAlert({ text: "You must select at least one role", type: "error" });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
          </OldButton>
        </div>
      </div>
      <Form
        onSubmit={onSubmit}
        schema={UserSchema}
        manualErrors={manualErrors}
        defaultValues={initialData}
      >
        <div className="card card-modal">
          <div className="card-body">
            <Flex>
              <Input id="id" name="id" hidden />
              <Input
                id="firstName"
                name="firstName"
                label={formatMessage("app.firstName", "Firstname")}
              />
              <Input
                id="middleName"
                name="middleName"
                label={formatMessage("app.middleName", "Middlename")}
              />
              <Input
                id="lastName"
                name="lastName"
                label={formatMessage("app.lastName", "Lastname")}
              />
            </Flex>
            <Flex mt="30px">
              <Input
                id="birthDate"
                name="birthDate"
                type="date"
                label={formatMessage("app.birthDate", "Birthdate")}
              />
              <Input
                id="socialSecurityNumber"
                name="socialSecurityNumber"
                label={formatMessage("app.socialSecurityNumber", "Social Security Number")}
              />
              <Select
                id="sex"
                name="sex"
                label={formatMessage("app.gender", "Gender")}
                options={gendersList?.map(option => ({
                  value: option.id,
                  label: option.name,
                }))}
              />
            </Flex>
            <Flex mt="30px">
              <Input
                id="completeAddress"
                name="completeAddress"
                type="text"
                label={formatMessage("app.completeAddress", "Complete Address")}
              />
            </Flex>
            <Flex mt="30px">
              <Select
                id="language"
                name="language"
                label={formatMessage("app.language", "Language")}
                options={languagesList?.map(option => ({
                  value: option.id,
                  label: option.name,
                }))}
              />
              <Select
                id="companyId"
                name="companyId"
                label={formatMessage("app.company", "Company")}
                options={companiesList?.map(option => ({
                  value: option.id,
                  label: option.companyName,
                }))}
              />
            </Flex>
          </div>
        </div>

        <div className="card card-modal mt-5">
          <div className="card-body">
            <Flex>
              {!isEditing && (
                <Input
                  id="username"
                  name="username"
                  type="text"
                  label={formatMessage("app.username", "Username")}
                />
              )}
              <Input
                id="email"
                name="email"
                type="text"
                label={formatMessage("app.email", "Email")}
              />
              {isEditing && (
                <Input
                  id="mobileNumber"
                  name="mobileNumber"
                  type="text"
                  label={formatMessage("app.mobileNumber", "Mobile Number")}
                />
              )}
            </Flex>
            {!isEditing && (
              <Flex mt="30px">
                <Input
                  id="mobileNumber"
                  name="mobileNumber"
                  type="text"
                  label={formatMessage("app.mobileNumber", "Mobile Number")}
                />
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label={formatMessage("app.password", "Password")}
                />
              </Flex>
            )}
            <Flex mt="30px">
              {!isEditing && (
                <Input
                  id="passwordrepeat"
                  name="passwordrepeat"
                  type="password"
                  width="75%"
                  label={formatMessage("app.passwordRepeat", "Repeat Password")}
                />
              )}
              <Checkbox
                id="mustChangePwd"
                name="mustChangePwd"
                label={formatMessage("app.passwordMustBeChanged", "Password must be changed")}
                wrapperProps={{ width: "25%" }}
              />
              <Checkbox
                id="passwordNeverExpires"
                name="passwordNeverExpires"
                label={formatMessage("app.passwordNeverExpires", "Password never expires")}
                wrapperProps={{ width: "25%" }}
              />
            </Flex>
          </div>
        </div>

        <div className="card card-modal mt-5">
          <div className="card-body">
            {applicationsList?.map((application, index) => {
              const applicationIdentifier = buildApplicationIdentifier(application);
              return (
                <Flex mt={index === 0 ? "" : "30px"}>
                  <Select
                    id={applicationIdentifier}
                    name={applicationIdentifier}
                    key={applicationIdentifier}
                    label={formatMessage(
                      "app.roleOn" + applicationIdentifier.slice(1),
                      "Role On " + application.description
                    )}
                    options={rolesList?.map(option => ({
                      value: option.id,
                      label: option.roleName,
                    }))}
                  />
                </Flex>
              );
            })}
          </div>
        </div>

        <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
          <Button id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
            {formatMessage("app.cancel", "Cancel")}
          </Button>
          <Button id="ModalBtn_save" type="submit">
            {formatMessage("app.save", "Save")}
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

function mapDispatchToProps(dispatch: Dispatch<ACTION>) {
  return {
    fetchRoles: bindActionCreators(fetchRoles, dispatch),
    fetchLanguages: bindActionCreators(fetchLanguages, dispatch),
    fetchApplications: bindActionCreators(fetchApplications, dispatch),
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    fetchGenders: bindActionCreators(fetchGenders, dispatch),
  };
}

const enhance = compose(connect(null, mapDispatchToProps), injectIntl);

export default enhance(UsersModal);

import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Drawer from "@components/Drawer";
import { Text } from "@components/Text";
import { OldInput } from "@components/OldInput";
import { AddButton } from "@components/AddButton";
import ExpandableRow from "@components/ExpandableRow";
import { ListContainer } from "@components/ListContainer";
import { Column } from "@components/ExpandableRow/styles";
import { ScrollableList } from "@components/ScrollableList";
import { Button } from "@components/Button";
import { useSwal } from "@hooks/useSwal";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import { ReactComponent as IconEdit } from "@assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "@assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";

import "../../../styles/index.scss";
import { fetchTenants } from "../../../actions/tenant";
import { TDispatch, TRootState } from "../../../redux/types";
import TenantMappingsModal from "./Modal/TenantMappingModal";
import { getParameterByName } from "../../../utils/toSearchParams";
import { fetchTenantMappingTypes } from "../../../actions/enumerator";
import {
  deleteTenantMapping,
  fetchTenantMappingList,
  getTenantMappingById,
  incrementTenantMappingsPageNo,
  setTenantMappingsPageNo,
} from "./ducks";

enum formMode {
  edit = "edit",
}

const TenantMappings: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTenantMappingForm, setAddTenantMappingForm] = useState<Record<string, unknown>>();
  const [tenantMappingFilter, setTenantMappingFilter] = useState({
    GenericText: null,
    dynamicFilter: "",
  });
  const [tenantList, setTenantList] = useState<Record<string, unknown>[]>();
  const [tenantMappingTypeList, setTenantMappingTypeList] = useState<Record<string, unknown>[]>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { tenantMappings, moreTenantMappings, tenantMappingsPageNo } = useSelector(
    ({
      TenantMappingsReducer: { tenantMappings, moreTenantMappings, tenantMappingsPageNo },
    }: TRootState) => ({
      tenantMappings,
      moreTenantMappings,
      tenantMappingsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTenantMappingList()).then(() => dispatch(incrementTenantMappingsPageNo()));
    dispatch(fetchTenants()).then(res => setTenantList(res));
    dispatch(fetchTenantMappingTypes()).then(res => setTenantMappingTypeList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTenantMappings) {
      dispatch(fetchTenantMappingList(tenantMappingsPageNo, 10, tenantMappingFilter)).then(() =>
        incrementTenantMappingsPageNo()
      );
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prevState => !prevState);
  };

  const filterTenantMappings = () => {
    dispatch(setTenantMappingsPageNo(1));
    fetchTenantMappingList(1, 10, tenantMappingFilter);
  };

  const clearFilters = () => {
    setTenantMappingFilter(prev => ({
      ...prev,
      dynamicFilter: "",
    }));

    dispatch(setTenantMappingsPageNo(1));
    dispatch(fetchTenantMappingList(1, 10));
  };

  const renderTenantMappingsSideBar = () => (
    <Drawer id="filterArea" opened={filtersOpened}>
      <div className="paddingContainer">
        <p className="colorGrey mb-1">Dynamic Filter</p>
        <div data-cy="dynamicFilterByField">
          <OldInput
            id="dynamicSearch"
            name="dynamicSearch"
            key="dynamicSearch"
            inputWrapperClass="text-input filter-input"
            placeholder={formatMessage("app.anyfield", "Any Field")}
            value={tenantMappingFilter.dynamicFilter}
            onChange={(name, value) => {
              setTenantMappingFilter(prev => ({
                ...prev,
                dynamicFilter: value,
              }));
            }}
            type="text"
            filtering
          />
        </div>
        <div className="breaker mb-5" />

        <div className="modal-buttons">
          <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
            {formatMessage("app.clear", "Clear")}
          </Button>

          <Button id="buttonSearch" textButton mr="10px" onClick={filterTenantMappings}>
            {formatMessage("app.search", "Search")}
          </Button>
        </div>
      </div>
    </Drawer>
  );

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterTenantMappings();
  };

  const openModal = (content, message?, tenantMapping?) => {
    getTenantMappingById(tenantMapping.id).then(res => {
      if (res.success) {
        setAddTenantMappingForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const saveHistoryAndFilterTenantMappings = () => {
    history.push({
      search: "",
    });
    filterTenantMappings();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant Mapping has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterTenantMappings
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this tenant mapping?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove tenant mapping",
    }).then(value => {
      if (value) {
        deleteTenantMapping(id).then(res => {
          if (res.success) showSuccessMessage("deleted");
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {modalIsOpen && (
          <TenantMappingsModal
            closeModal={closeModal}
            initialData={addTenantMappingForm}
            tenantList={tenantList}
            tenantMappingTypeList={tenantMappingTypeList}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addTenantMappingBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewTenantMapping")}
            onClick={() => {
              setAddTenantMappingForm({});
              setModalIsOpen(true);
            }}
          />

          <ScrollableList
            items={tenantMappings}
            renderHeader={
              <>
                <Column>
                  <Text>{formatMessage("app.tenantName")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.type")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.value")}</Text>
                </Column>
                <Column icon>
                  <div
                    id="toggleFilterArea"
                    className="icon-filter"
                    tabIndex={0}
                    onClick={e => {
                      e.stopPropagation();
                      changeFilterOpened();
                    }}
                  >
                    <IconFilter />
                  </div>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                data-cy="tenantMappingRow"
                key={"tenantMappingRow" + index}
                id={"tenantMappingRow" + index}
                index={index}
                translucent={false}
                expandable={false}
                top={
                  <>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="tenantName">
                        {item.tenantName}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="typeName">
                        {item.typeName}
                      </Text>
                    </Column>
                    <Column>
                      <Text light={false} fontSize="16px" data-cy="value">
                        {item.value}
                      </Text>
                    </Column>
                    <Column icon>
                      <Flex>
                        <div
                          data-cy="changeEntry"
                          className="iconWrapper pointer large"
                          title="Edit Mapping"
                          onClick={() => openModal(formMode.edit, "", item)}
                        >
                          <IconEdit />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete Mapping"
                          onClick={() => remove(item.id)}
                        >
                          <IconTrash />
                        </div>
                      </Flex>
                    </Column>
                  </>
                }
              />
            )}
            onLoadMore={handleScroll}
          />
        </Flex>
        {renderTenantMappingsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default TenantMappings;

import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import dateFormat from "dateformat";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Drawer from "@components/Drawer";
import { Text } from "@components/Text";
import { OldInput } from "@components/OldInput";
import { AddButton } from "@components/AddButton";
import ExpandableRow from "@components/ExpandableRow";
import { Column } from "@components/ExpandableRow/styles";
import { ListContainer } from "@components/ListContainer";
import { Button } from "@components/Button";
import { ScrollableList } from "@components/ScrollableList";
import { useSwal } from "@hooks/useSwal";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import { ReactComponent as IconEdit } from "@assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "@assets/icons/trash.svg";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";

import "../../../styles/index.scss";
import VersionsModal from "./Modal/VersionsModal";
import { fetchTenants } from "../../../actions/tenant";
import { TDispatch, TRootState } from "../../../redux/types";
import { fetchApplications } from "../../../actions/enumerator";
import { getParameterByName } from "../../../utils/toSearchParams";
import { incrementVersionLogPageNo } from "../../Logs/VersionLog/ducks";
import { deleteVersion, fetchVersionList, getVersionById, setVersionsPageNo } from "./ducks";

enum formMode {
  edit = "edit",
  password = "password",
}

const Versions: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addVersionForm, setAddVersionForm] = useState("");
  const [versionFilter, setVersionFilter] = useState({
    GenericText: null,
    dynamicFilter: "",
  });
  const [applicationList, setApplicationList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const history = useHistory();
  const location = useLocation();
  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { versions, moreVersions, versionsPageNo } = useSelector(
    ({ VersionReducer: { versions, moreVersions, versionsPageNo } }: TRootState) => ({
      versions,
      moreVersions,
      versionsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchVersionList()).then(() => dispatch(incrementVersionLogPageNo()));
    dispatch(fetchApplications()).then(res => setApplicationList(res));
    dispatch(fetchTenants()).then(res => setTenantList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreVersions) {
      dispatch(fetchVersionList(versionsPageNo, 10, versionFilter)).then(() =>
        dispatch(incrementVersionLogPageNo())
      );
    }
  };

  const filterConnections = () => {
    dispatch(setVersionsPageNo(1));
    dispatch(fetchVersionList(1, 10, versionFilter));
  };

  const clearFilters = () => {
    setVersionFilter({ GenericText: null, dynamicFilter: "" });

    dispatch(setVersionsPageNo(1));
    dispatch(fetchVersionList());
  };

  const renderVersionsSideBar = () => (
    <Drawer id="filterArea" opened={filtersOpened}>
      <div className="paddingContainer">
        <p className="colorGrey mb-1">Dynamic Filter</p>
        <div data-cy="dynamicFilterByField">
          <OldInput
            id="dynamicSearch"
            name="dynamicSearch"
            key="dynamicSearch"
            inputWrapperClass="text-input filter-input"
            placeholder={formatMessage("app.anyfield", "Any Field")}
            value={versionFilter.dynamicFilter}
            onChange={(name, value) =>
              setVersionFilter(prev => ({
                ...prev,
                dynamicFilter: value,
              }))
            }
            validationMessageConfig={null}
            type="text"
            filtering
          />
        </div>

        <div className="breaker mb-5" />

        <div className="modal-buttons">
          <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
            {formatMessage("app.clear", "Clear")}
          </Button>

          <Button id="buttonSearch" textButton mr="10px" onClick={filterConnections}>
            {formatMessage("app.search", "Search")}
          </Button>
        </div>
      </div>
    </Drawer>
  );

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterConnections();
  };

  const openModal = (content, message?, entry?) => {
    if (content !== formMode.password.toString()) {
      getVersionById(entry.id).then(res => {
        if (res.success) {
          setAddVersionForm(deserialize(res.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      setAddVersionForm(entry);
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterVersions = () => {
    history.push({
      search: "",
    });
    filterConnections();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Version has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterVersions
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this version?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove version",
    }).then(value => {
      if (value) {
        deleteVersion(id).then(res => {
          if (res.success) showSuccessMessage("removed");
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && (
          <VersionsModal
            closeModal={closeModal}
            initialData={addVersionForm}
            applicationList={applicationList}
            tenantList={tenantList}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addVersionBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewversion", "Add Version")}
            onClick={() => {
              setAddVersionForm("");
              setModalIsOpen(true);
            }}
          />

          <ScrollableList
            items={versions}
            renderHeader={
              <>
                <Column size={1}>
                  <Text>{formatMessage("app.fullVersion", "Version")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.applicationName", "Application")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.createdDate", "Create Date")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.endDate", "End Date")}</Text>
                </Column>
                <Column size={1}>
                  <Text>{formatMessage("app.tenantDescription", "Tenant")}</Text>
                </Column>
                <Column icon>
                  <div
                    id="toggleFilterArea"
                    className="icon-filter"
                    tabIndex={0}
                    onClick={e => {
                      e.stopPropagation();
                      setFiltersOpened(prev => !prev);
                    }}
                  >
                    <IconFilter />
                  </div>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                id={`Version${index}`}
                key={`Version${index}`}
                top={
                  <>
                    <Column size={1}>
                      <div data-cy="fullVersion" className="d-flex flex-row">
                        <Text light={false} fontSize="16px">
                          {item.fullVersion}
                        </Text>
                      </div>
                    </Column>
                    <Column size={1}>
                      <div data-cy="applicationName" className="d-flex flex-row">
                        <Text light={false} fontSize="16px">
                          {item.applicationName}
                        </Text>
                      </div>
                    </Column>
                    <Column size={1}>
                      <div data-cy="createdDate" className="d-flex flex-row">
                        <Text light={false} fontSize="16px">
                          {dateFormat(item.createdDate, "yyyy/mm/dd HH:mm")}
                          <br />
                          {item.createdDate}
                        </Text>
                      </div>
                    </Column>
                    <Column size={1}>
                      <div data-cy="endDate" className="d-flex flex-row">
                        <Text light={false} fontSize="16px">
                          {dateFormat(item.endDate, "yyyy/mm/dd HH:mm")}
                          <br />
                          {item.endDate}
                        </Text>
                      </div>
                    </Column>
                    <Column size={1}>
                      <div data-cy="tenantDescription" className="d-flex flex-row">
                        <Text light={false} fontSize="16px">
                          {item.tenantDescription}
                        </Text>
                      </div>
                    </Column>

                    <Column icon>
                      <div className="d-flex flex-row showChildOnHover">
                        <div
                          data-cy="changeEntry"
                          className="iconWrapper pointer large"
                          title="Edit Version"
                          onClick={() => openModal(formMode.edit, "", item)}
                        >
                          <IconEdit />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete Version"
                          onClick={() => remove(item.id)}
                        >
                          <IconTrash />
                        </div>
                      </div>
                    </Column>
                  </>
                }
              />
            )}
            onLoadMore={handleScroll}
          />
        </Flex>
        {renderVersionsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default Versions;

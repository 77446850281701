export const SIDEBAR_SET = "SIDEBAR_SET";
export const LOADING = "LOADING";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_REFRESH_TOKEN = "LOGIN_REFRESH_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_LOCALES = "UPDATE_LOCALES";
export const UPDATE_EXPIRATION = "UPDATE_EXPIRATION";

export const FETCH_ERROR = "FETCH_ERROR";
export const POST_ERROR = "POST_ERROR";
export const PATCH_ERROR = "PATCH_ERROR";
export const DELETE_ERROR = "DELETE_ERROR";

export const POST_SUCCESS = "POST_SUCCESS";
export const PATCH_SUCCESS = "PATCH_SUCCESS";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

export const FETCHED_ROLES = "FETCHED_ROLES";
export const SET_FILTERING_CRITERIA_ROLES = "SET_FILTERING_CRITERIA_ROLES";
export const SET_ROLES_PAGE_NO = "SET_ROLES_PAGE_NO";
export const SET_ORDER_CRITERIA_ROLES = "SET_ORDER_CRITERIA_ROLES";

export const FETCHED_MAILSERVERSETTINGS = "FETCHED_MAILSERVERSETTINGS";
export const SET_FILTERING_CRITERIA_MAILSERVERSETTINGS =
  "SET_FILTERING_CRITERIA_MAILSERVERSETTINGS";
export const SET_MAILSERVERSETTINGS_PAGE_NO = "SET_MAILSERVERSETTINGS_PAGE_NO";

export const FETCHED_CONNECTIONS = "FETCHED_CONNECTIONS";
export const SET_FILTERING_CRITERIA_CONNECTIONS = "SET_FILTERING_CRITERIA_CONNECTIONS";
export const SET_CONNECTIONS_PAGE_NO = "SET_CONNECTIONS_PAGE_NO";

export const FETCHED_WEBSERVICES = "FETCHED_WEBSERVICES";
export const SET_FILTERING_CRITERIA_WEBSERVICES = "SET_FILTERING_CRITERIA_WEBSERVICES";
export const SET_WEBSERVICES_PAGE_NO = "SET_WEBSERVICES_PAGE_NO";

export const FETCHED_OBJECTS = "FETCHED_OBJECTS";
export const SET_FILTERING_CRITERIA_OBJECTS = "SET_FILTERING_CRITERIA_OBJECTS";
export const SET_OBJECTS_PAGE_NO = "SET_OBJECTS_PAGE_NO";

export const FETCHED_OBJECTROLES = "FETCHED_OBJECTROLES";
export const SET_FILTERING_CRITERIA_OBJECTROLES = "SET_FILTERING_CRITERIA_OBJECTROLES";
export const SET_OBJECTROLES_PAGE_NO = "SET_OBJECTROLES_PAGE_NO";

export const FETCHED_EMAILLOGS = "FETCHED_EMAILLOGS";
export const SET_FILTERING_CRITERIA_EMAILLOGS = "SET_FILTERING_CRITERIA_EMAILLOGS";
export const SET_EMAILLOGS_PAGE_NO = "SET_EMAILLOGS_PAGE_NO";

export const FETCHED_APPLICATIONS = "FETCHED_APPLICATIONS";
export const SET_FILTERING_CRITERIA_APPLICATIONS = "SET_FILTERING_CRITERIA_APPLICATIONS";
export const SET_APPLICATIONS_PAGE_NO = "SET_APPLICATIONS_PAGE_NO";

export const POPUP = "POPUP";

export const PAGINATION_SIZE = 10;

import { push } from "connected-react-router";
import { Cookies } from "react-cookie";

import { ACTION } from "../models/action";
import { setLoading } from "../../actions";
import apiCaller from "../../api/apiHandler";
import * as constants from "../../constants";
import requestWraper from "../../api/requestWraper";
import { store } from "../../redux/store";

const cookies = new Cookies();

export const loginAct = (payload: any): ACTION => ({
  type: constants.LOGIN,
  payload,
});

export const loginSuccess = (payload: any): ACTION => ({
  type: constants.LOGIN_SUCCESS,
  payload,
});

export const login = payload =>
  requestWraper(dispatch => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      dispatch(setLoading(true));
      let newPayload = {};
      if (payload.step === "2fa") {
        newPayload = {
          bearerToken: payload.token,
          pin: payload.pin,
        };
      } else if (payload.oneTimeToken) {
        newPayload = {
          data: {
            oneTimeToken: payload.oneTimeToken,
          },
        };
      } else {
        newPayload = {
          data: {
            username: payload.login,
            password: payload.password,
          },
        };
      }
      return apiCaller("POST", "Authenticate/Login", { ...newPayload }, headers, true).then(
        (response: any) => {
          const bearerToken = Object.prototype.hasOwnProperty.call(response, "token")
            ? response.token
            : null;

          if (bearerToken) {
            cookies.set("bearerToken", bearerToken, {});
            dispatch(setLoading(false));

            if (response.message === "CheckMfa") {
              payload.step = "2fa";
              payload.token = bearerToken;
              dispatch(loginAct(payload));
              setTimeout(() => {
                dispatch(push("/login?2fa=1"));
              }, 150);
            } else if (response.message === "Success") {
              response.step = "loggedin";
              dispatch(loginSuccess(response));
            }
          } else {
            if (response.message === "MfaFailed") {
              return response.message;
            } else if (response.message === "PasswordExpired") {
              cookies.remove("bearerToken");
              // eslint-disable-next-line
              setTimeout(() => {
                dispatch(push("/login?password-reset=2&token=" + response.resetToken));
              }, 150);
              // @TODO: to translate
              throw store.getState().intl.messages["app.accountexpired"];
            } else if (response.message === "UserLockedOut") {
              cookies.remove("bearerToken");
              // eslint-disable-next-line
              setTimeout(() => {
                dispatch(push("/login?password-reset=1&user="));
              }, 150);
              // @TODO: to translate
              throw store.getState().intl.messages["app.accountlocked"];
            } else {
              throw Error("User or Password are incorrect.");
            }
          }
        }
      );
    } catch (err) {
      dispatch(setLoading(false));
      cookies.remove("bearerToken");
      // @TODO: better german translation
      throw new Error(err.message || "Etwas ist schief gelaufen!");
    }
  });

export const logOutLocally = (): ACTION => ({
  type: constants.LOG_OUT,
});

export const logOut = () =>
  requestWraper(dispatch => {
    try {
      return apiCaller("POST", "Authenticate/logout", {}).then(() => {
        dispatch(push("/login"));
        dispatch({ type: constants.LOG_OUT, payload: null });
      });
    } catch (err) {
      throw err || "\n" + "Something went wrong!";
    }
  });

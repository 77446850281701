import http from "../../../api/http";
import { TObjectRolesState } from "./types";
import { TAction } from "../../../redux/types";
import { LOADING, PAGINATION_SIZE } from "../../../constants";
import {
  buildFilterQueryString,
  // buildOrderQueryString,
  deserialize,
  serialize,
} from "../../../api/jsonApiParser";

import { Dispatch } from "redux";
import { TResponse } from "@api/types";

export const FETCHED_OBJECTROLES = "FETCHED_OBJECTROLES";
export const SET_FILTERING_CRITERIA_OBJECTROLES = "SET_FILTERING_CRITERIA_OBJECTROLES";
export const SET_ORDER_CRITERIA_OBJECTROLES = "SET_ORDER_CRITERIA_OBJECTROLES";
export const SET_OBJECTROLES_PAGE_NO = "SET_OBJECTROLES_PAGE_NO";
export const INCREMENT_OBJECTROLES_PAGE_NO = "INCREMENT_OBJECTROLES_PAGE_NO";

const initialState = {
  objectRoles: [],
  objectRolesPageNo: 1,
  moreObjectRoles: true,
  filter: undefined,
  order: [],
};

const ObjectRolesReducer = (state: TObjectRolesState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_OBJECTROLES: {
      let objectRoles = [];
      const moreObjectRoles = !(action.payload.length < PAGINATION_SIZE);

      if (state.objectRolesPageNo === 1 || action.page === 1) {
        objectRoles = [...action.payload];
      } else {
        objectRoles = [...state.objectRoles, ...action.payload];
      }

      return { ...state, objectRoles: objectRoles, moreObjectRoles: moreObjectRoles };
    }

    case SET_OBJECTROLES_PAGE_NO: {
      const moreObjectRoles = !(
        state.objectRoles.length <
        (state.objectRolesPageNo + 1) * PAGINATION_SIZE
      );
      return { ...state, objectRolesPageNo: action.payload, moreObjectRoles: moreObjectRoles };
    }

    case SET_FILTERING_CRITERIA_OBJECTROLES: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }
  }
  return state;
};

export const setObjectRolesPageNo = (payload: number): TAction => ({
  type: SET_OBJECTROLES_PAGE_NO,
  payload,
});

export const incrementObjectRolesPageNo = () => ({
  type: INCREMENT_OBJECTROLES_PAGE_NO,
});

export const fetchObjectRolesList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });

    const query = `ObjectRole/ObjectRolePaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse) => {
        dispatch({
          type: FETCHED_OBJECTROLES,
          payload: (response.data as Record<string, unknown>[]).map(objects =>
            deserialize(objects)
          ),
          page: page,
        });

        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const createObjectRole = payload => {
  return http.post("ObjectRole/", serialize(payload, "ObjectRole"));
};

export const changeObjectRole = (id: string, payload) => {
  return http.put("ObjectRole/" + id, serialize(payload, "ObjectRole"));
};

export const deleteObjectRole = (id: string) => {
  return http.delete("ObjectRole/" + id);
};

export const getObjectRoleById = (id: string) => {
  return http.get("ObjectRole/" + id);
};

export { ObjectRolesReducer };

import apiCaller from "../api/apiHandler";
import * as constants from "../constants";
import requestWraper from "../api/requestWraper";
import { deserialize } from "../api/jsonApiParser";
import { store } from "../redux/store";

export const fetchRoles = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      // get filters, orders and page from store
      const page = store.getState().RolesReducer.rolesPageNo;

      // TODO: filter/order not yet implemented
      // const filters = store.getState().roles.filter;
      // const orders = store.getState().roles.order;
      // const query = `User/UserPaginated?Page=${page}&PageSize=${pageSize}`;
      // const filterQuery = filters ? buildFilterQueryString(filters, query) : query;
      // const orderQuery = orders ? buildOrderQueryString(orders, filterQuery) : filterQuery;

      return await apiCaller("GET", `Role/`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        dispatch({
          type: constants.FETCHED_ROLES,
          payload: response.data.map(role => deserialize(role)),
          page: page,
        });
        return response.data.map(role => deserialize(role));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Something went wrong!";
    }
    return true;
  });

import React, { FC, useEffect } from "react";
import { Flex } from "reflexbox";
import { useIntl } from "react-intl";

import { Form } from "@components/Form";
import { Button } from "@components/Button";
import { FormInput as Input } from "@components/Form/Input";
import { ReactComponent as IconArrowRight } from "@assets/icons/arrow-right.svg";
import { useSwal } from "@hooks/useSwal";

import { TModalPassword } from "./types";
import { MailServerPasswordSchema } from "./schema";
import { changePasswordMailServerSetting } from "../ducks";

const MailServerSettingsModalPassword: FC<TModalPassword> = ({ closeModal, initialData }) => {
  const intl = useIntl();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Password has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    swalConfirmationMessage({
      text: "Do you really want to change this mail server setting password?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Change password",
    }).then(value => {
      if (value) {
        const { id, password } = formData;

        changePasswordMailServerSetting(id, password).then(res => {
          if (res.success) showSuccessMessage("changed");
        });
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <Button id="closeModalBtn" onClick={closeModal} textButton>
            <div className="iconWrapper arrow left mr-3 small">
              <IconArrowRight />
            </div>
            {intl.formatMessage({
              id: "app.backToOverview",
              defaultMessage: "Zurück zur Übersicht",
            })}
          </Button>
        </div>
      </div>
      <Flex flexDirection="column">
        <Form onSubmit={onSubmit} schema={MailServerPasswordSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="password"
              name="password"
              type="password"
              width="50%"
              label={intl.formatMessage({ id: "app.password" })}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="passwordrepeat"
              name="passwordrepeat"
              type="password"
              width="50%"
              label={intl.formatMessage({
                id: "app.passwordrepeat",
                defaultMessage: "Repeat password",
              })}
            />
          </Flex>
          <Flex justifyContent="flex-end" mt="30px" width="50%">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal}>
              {intl.formatMessage({ id: "app.cancel", defaultMessage: "Cancel" })}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {intl.formatMessage({ id: "app.save", defaultMessage: "Save" })}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default MailServerSettingsModalPassword;

import React, { FC, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import Drawer from "@components/Drawer";
import { Text } from "@components/Text";
import { Checkbox } from "@components/Checkbox";
import { OldInput } from "@components/OldInput";
import ExpandableRow from "@components/ExpandableRow";
import { ListContainer } from "@components/ListContainer";
import { Column } from "@components/ExpandableRow/styles";
import { ScrollableList } from "@components/ScrollableList";
import { Button } from "@components/Button";

import { ReactComponent as IconEdit } from "@assets/icons/edit.svg";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";

import "../../../styles/index.scss";
import { fetchTenants } from "../../../actions/tenant";
import { TDispatch, TRootState } from "../../../redux/types";
import { getParameterByName } from "../../../utils/toSearchParams";
import TenantTranslationsModal from "./Modal/TenantTranslationModal";
import {
  fetchTenantTranslationList,
  getTenantTranslationById,
  incrementTenantTranslationPageNo,
  setTenantTranslationsPageNo,
} from "./ducks";
import {
  fetchBaseTextTranslates,
  fetchLanguages,
  fetchLayers,
  fetchModules,
  fetchTranslationTypes,
} from "../../../actions/enumerator";

enum formMode {
  edit = "edit",
}

const TenantTranslations: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTenantTranslationForm, setAddTenantTranslationForm] = useState(null);
  const [tenantTranslationFilter, setTenantTranslationFilter] = useState({
    GenericText: null,
    TenantId: null,
    Layer: null,
    Module: null,
    TranslationType: null,
    Language: null,
    TranslationId: null,
    TextTranslated: null,
    ListNotTranslated: null,
    translationId: null,
    dynamicFilter: "",
  });
  const [languageList, setLanguageList] = useState<Record<string, unknown>[]>(null);
  const [baseTextTranslateIdList, setBaseTextTranslateIdList] = useState<Record<string, unknown>[]>(
    null
  );
  const [translationTypeList, setTranslationTypeList] = useState<Record<string, unknown>[]>(null);
  const [moduleList, setModuleList] = useState<Record<string, unknown>[]>(null);
  const [layerList, setLayerList] = useState<Record<string, unknown>[]>(null);
  const [tenantList, setTenantList] = useState<Record<string, unknown>[]>(null);

  const formatMessage = useFormatMessage();
  const history = useHistory();
  const location = useLocation();
  const dispatch: TDispatch = useDispatch();

  const { tenantTranslations, moreTenantTranslations } = useSelector(
    ({
      TenantTranslationReducer: {
        tenantTranslations,
        moreTenantTranslations,
        tenantTranslationsPageNo,
      },
    }: TRootState) => ({
      tenantTranslations,
      moreTenantTranslations,
      tenantTranslationsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTenantTranslationList()).then(() => dispatch(incrementTenantTranslationPageNo()));
    dispatch(fetchLanguages()).then(res => setLanguageList(res));
    dispatch(fetchBaseTextTranslates()).then(res => setBaseTextTranslateIdList(res));
    dispatch(fetchTranslationTypes()).then(res => setTranslationTypeList(res));
    dispatch(fetchModules()).then(res => setModuleList(res));
    dispatch(fetchLayers()).then(res => setLayerList(res));
    dispatch(fetchTenants()).then(res => setTenantList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTenantTranslations) {
      dispatch(fetchTenantTranslationList()).then(() => dispatch(incrementTenantTranslationPageNo));
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterTenantTranslations = () => {
    dispatch(setTenantTranslationsPageNo(1));
    dispatch(fetchTenantTranslationList(1, 10, tenantTranslationFilter));
  };

  const clearFilters = () => {
    setTenantTranslationFilter({
      GenericText: null,
      TenantId: null,
      Layer: null,
      Module: null,
      TranslationType: null,
      Language: null,
      TranslationId: null,
      TextTranslated: null,
      ListNotTranslated: null,
      translationId: null,
      dynamicFilter: "",
    });

    dispatch(setTenantTranslationsPageNo(1));
    dispatch(fetchTenantTranslationList());
  };

  const prepareLanguageOptions = useCallback(() => {
    if (languageList) {
      return languageList.map(item => ({
        id: item.id,
        dropdownLabel: item.description,
      }));
    }
  }, [languageList]);

  const prepareModuleOptions = useCallback(() => {
    if (moduleList) {
      return moduleList.map(item => ({
        id: item.id,
        dropdownLabel: item.description,
      }));
    }
  }, [moduleList]);

  const prepareTranslationTypeOptions = useCallback(() => {
    if (translationTypeList) {
      return translationTypeList.map(item => ({
        id: item.id,
        dropdownLabel: item.description,
      }));
    }
  }, [translationTypeList]);

  const prepareLayerOptions = useCallback(() => {
    if (layerList) {
      return layerList.map(item => ({
        id: item.id,
        dropdownLabel: item.description,
      }));
    }
  }, [layerList]);

  const prepareTenantOptions = useCallback(() => {
    if (tenantList) {
      return tenantList.map(item => ({
        id: item.id,
        dropdownLabel: item.description,
      }));
    }
  }, [tenantList]);

  const renderTranslationsSideBar = () => {
    const languageOptions = prepareLanguageOptions();
    const layerOptions = prepareLayerOptions();
    const moduleOptions = prepareModuleOptions();
    const translationTypeOptions = prepareTranslationTypeOptions();
    const tenantOptions = prepareTenantOptions();

    return (
      <Drawer id="filterArea" opened={filtersOpened}>
        <div className="paddingContainer">
          <p className="colorGrey mb-1">Dynamic Filter</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="dynamicSearch"
              name="dynamicSearch"
              key="dynamicSearch"
              type="text"
              filtering
              inputWrapperClass="text-input filter-input"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={tenantTranslationFilter.dynamicFilter}
              onChange={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  dynamicFilter: value,
                }));
              }}
            />
          </div>

          <div className="breaker mb-5" />

          <p className="colorGrey mb-1">
            <FormattedMessage id="app.TenantId" defaultMessage="Tenant" />
          </p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="TenantIdInput"
              name="TenantId"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                tenantTranslationFilter.TenantId
                  ? tenantOptions.find(x => x.id === tenantTranslationFilter.TenantId).dropdownLabel
                  : ""
              }
              selectOptions={tenantOptions}
              onSelected={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  TenantId: value,
                }));
              }}
              onChange={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  TenantId: value,
                }));
              }}
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1">
            <FormattedMessage id="app.Layer" defaultMessage="Layer" />
          </p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="LayerInput"
              name="Layer"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                tenantTranslationFilter.Layer
                  ? layerOptions.find(x => x.id === tenantTranslationFilter.Layer).dropdownLabel
                  : ""
              }
              selectOptions={layerOptions}
              onSelected={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  Layer: value,
                }));
              }}
              onChange={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  Layer: value,
                }));
              }}
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1 mt-5">
            <FormattedMessage id="app.Module" defaultMessage="Module" />
          </p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="ModuleInput"
              name="Module"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                tenantTranslationFilter.Module
                  ? moduleOptions.find(x => x.id === tenantTranslationFilter.Module).dropdownLabel
                  : ""
              }
              selectOptions={moduleOptions}
              onSelected={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  Module: value,
                }));
              }}
              onChange={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  Module: value,
                }));
              }}
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1 mt-5">
            <FormattedMessage id="app.TranslationType" defaultMessage="Translation Type" />
          </p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="TranslationTypeInput"
              name="TranslationType"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                tenantTranslationFilter.TranslationType
                  ? translationTypeOptions.find(
                      x => x.id === tenantTranslationFilter.TranslationType
                    ).dropdownLabel
                  : ""
              }
              selectOptions={translationTypeOptions}
              onSelected={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  TranslationType: value,
                }));
              }}
              onChange={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  TranslationType: value,
                }));
              }}
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1 mt-5">
            <FormattedMessage id="app.language" defaultMessage="Language" />
          </p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="languageInput"
              name="language"
              isFocused={false}
              readOnly={false}
              inputWrapperClass="w-100 high"
              value={
                tenantTranslationFilter.Language
                  ? languageOptions.find(x => x.id === tenantTranslationFilter.Language)
                      .dropdownLabel
                  : ""
              }
              selectOptions={languageOptions}
              onSelected={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  Language: value,
                }));
              }}
              onChange={(name, value) => {
                setTenantTranslationFilter(prev => ({
                  ...prev,
                  Language: value,
                }));
              }}
              type="dropdown"
            />
          </div>
          <p className="colorGrey mb-1 mt-5">
            <FormattedMessage id="app.translationId" defaultMessage="Translation Id" />
          </p>
          <div data-cy="dynamicFilterByField">
            <div className="inputContainer">
              <input
                id="translationIdInput"
                name="translationId"
                className="inputText textMaterial "
                type="text"
                value={tenantTranslationFilter.translationId || ""}
                onChange={e => {
                  const value = e.currentTarget.value;
                  setTenantTranslationFilter(prev => ({
                    ...prev,
                    translationId: value,
                  }));
                }}
              />
            </div>
          </div>
          <p className="colorGrey mb-1 mt-5">
            <FormattedMessage id="app.textTranslated" defaultMessage="Text translated" />
          </p>
          <div data-cy="dynamicFilterByField">
            <div className="inputContainer">
              <input
                id="textTranslatedInput"
                name="textTranslated"
                className="inputText textMaterial "
                type="text"
                value={tenantTranslationFilter.TextTranslated || ""}
                onChange={e => {
                  const value = e.currentTarget.value;
                  setTenantTranslationFilter(prev => ({
                    ...prev,
                    TextTranslated: value,
                  }));
                }}
              />
            </div>
          </div>
          <p className="colorGrey mb-1 mt-5" />
          <div data-cy="dynamicFilterByField">
            <label
              htmlFor="ListNotTranslated"
              className="checkboxLabel"
              onKeyPress={event => {
                const code = event.keyCode || event.which;
                if (code === 13) {
                  setTenantTranslationFilter(prev => ({
                    ...prev,
                    ListNotTranslated: !prev.ListNotTranslated,
                  }));
                }
              }}
            >
              <label
                className="inputLabel"
                onClick={() => {
                  setTenantTranslationFilter(prev => ({
                    ...prev,
                    ListNotTranslated: !prev.ListNotTranslated,
                  }));
                }}
              >
                <FormattedMessage id="app.listNotTranslated" defaultMessage="List not translated" />
              </label>
              <input
                id="ListNotTranslatedInput"
                name="ListNotTranslated"
                tabIndex={-1}
                className="checkboxInput"
                type="checkbox"
                value={tenantTranslationFilter.ListNotTranslated || ""}
                checked={tenantTranslationFilter.ListNotTranslated || false}
                onChange={e => {
                  const value = e.currentTarget.checked;
                  setTenantTranslationFilter(prev => ({
                    ...prev,
                    ListNotTranslated: value,
                  }));
                }}
              />
              <i
                onClick={() => {
                  setTenantTranslationFilter(prev => ({
                    ...prev,
                    ListNotTranslated: !prev.ListNotTranslated,
                  }));
                }}
                className={
                  "checkboxMark " + (tenantTranslationFilter.ListNotTranslated ? "checked" : "")
                }
              />
            </label>
          </div>
          <div className="breaker mb-5" />

          <div className="modal-buttons">
            <Button id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
              {formatMessage("app.clear", "Clear")}
            </Button>

            <Button id="buttonSearch" textButton mr="10px" onClick={filterTenantTranslations}>
              {formatMessage("app.search", "Search")}
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterTenantTranslations();
  };

  const openModal = (content, message?, tenantTranslation?) => {
    getTenantTranslationById(tenantTranslation.id).then(res => {
      if (res.success) {
        setAddTenantTranslationForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const renderModalContent = () =>
    modalIsOpen && (
      <TenantTranslationsModal
        closeModal={closeModal}
        initialData={addTenantTranslationForm}
        languageList={languageList}
        baseTextTranslateIdList={baseTextTranslateIdList}
        translationTypeList={translationTypeList}
        moduleList={moduleList}
        layerList={layerList}
        tenantList={tenantList}
      />
    );

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {renderModalContent()}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <ScrollableList
          items={tenantTranslations}
          renderHeader={
            <>
              <Column>
                <Text>{formatMessage("app.layer")}</Text>
              </Column>
              <Column>
                <Text>{formatMessage("app.module")}</Text>
              </Column>
              <Column>
                <Text>{formatMessage("app.language")}</Text>
              </Column>
              <Column>
                <Text>{formatMessage("app.text")}</Text>
              </Column>
              <Column>
                <Text>{formatMessage("app.translated")}</Text>
              </Column>
              <Column icon>
                <div
                  id="toggleFilterArea"
                  className="icon-filter"
                  tabIndex={0}
                  onClick={e => {
                    e.stopPropagation();
                    changeFilterOpened();
                  }}
                >
                  <IconFilter />
                </div>
              </Column>
            </>
          }
          renderItem={(item, index) => (
            <ExpandableRow
              data-cy="tenantTranslationRow"
              id={`tenantTranslationRow${index}`}
              key={`tenantTranslationRow${index}`}
              index={index}
              translucent={false}
              expandable={true}
              top={
                <>
                  <Column>
                    <Text light={false} fontSize="16px" data-cy="layerName">
                      {item.layerName}
                    </Text>
                  </Column>
                  <Column>
                    <Text light={false} fontSize="16px" data-cy="moduleName">
                      {item.moduleName}
                    </Text>
                  </Column>
                  <Column>
                    <Text light={false} fontSize="16px" data-cy="languageCodeIso">
                      {item.languageCodeIso}
                    </Text>
                  </Column>
                  <Column>
                    <Text light={false} fontSize="16px" data-cy="originalText">
                      {item.originalText}
                    </Text>
                  </Column>
                  <Column>
                    <Text light={false} fontSize="16px" data-cy="textTranslated">
                      {item.textTranslated}
                    </Text>
                  </Column>
                  <Column icon>
                    <div
                      data-cy="changeEntry"
                      className="iconWrapper pointer large"
                      title="Edit Translation"
                      onClick={() => openModal(formMode.edit, "", item)}
                    >
                      <IconEdit />
                    </div>
                  </Column>
                </>
              }
              more={item => (
                <>
                  <Flex width="100%" mb="20px">
                    <Flex flexDirection="column" flex={1} mr="20px">
                      <Text mb="20px">{formatMessage("tenant")}</Text>
                      <Text light={false} fontSize="16px">
                        {item.tenantName}
                      </Text>
                    </Flex>

                    <Flex flexDirection="column" flex={1} mr="20px">
                      <Text mb="20px">{formatMessage("app.translationType")}</Text>
                      <Text light={false} fontSize="16px">
                        {item.translationTypeName}
                      </Text>
                    </Flex>

                    <Flex flexDirection="column" flex={1} mr="20px">
                      <Text mb="20px">{formatMessage("app.translationId")}</Text>
                      <Text light={false} fontSize="16px">
                        {item.translationId}
                      </Text>
                    </Flex>

                    <Flex flexDirection="column" flex={1} mr="20px">
                      <Text mb="20px">{formatMessage("app.automaticTranslation")}</Text>
                      <Checkbox
                        readOnly
                        id="TableAutomaticTranslationInput"
                        value={item.automaticTranslation}
                      />
                    </Flex>
                  </Flex>
                </>
              )}
            />
          )}
          onLoadMore={handleScroll}
        />
        {renderTranslationsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default TenantTranslations;

import React, { FC, useEffect, useState } from "react";
import {
  generateEmailTokenValidation,
  generateSMSTokenValidation,
  validateSMSToken,
} from "../ducks";
import { Flex } from "reflexbox";
import { useHistory } from "react-router-dom";

import { TLoginTokenModalProps, TSMSTokenValidation } from "./types";
import { StageEnum } from "../../../shared/models/StageEnum";
import { LoginTokenEmailSchema, LoginTokenSMSSchema } from "./schema";

import { Form } from "@components/Form";
import { Button } from "@components/Button";
import { useFormatMessage } from "@hooks/formatMessage";
import { FormInput as Input } from "@components/Form/Input";
import { TResponse } from "@api/types";

const appUrl = process.env.REACT_APP_URL;

const LoginTokenModal: FC<TLoginTokenModalProps> = props => {
  const [pageStageEnum, setPageStageEnum] = useState<StageEnum>(
    props.initialEmailToken ? StageEnum.RequestSMSToken : StageEnum.RequestEmail
  );
  const [formData, setFormData] = useState(undefined);
  const [mobilePhone, setMobilePhone] = useState<string>(
    props.mobilePhone ? props.mobilePhone : ""
  );
  const [emailToken, setEmailToken] = useState<string>(
    props.initialEmailToken ? props.initialEmailToken : undefined
  );

  const formatMessage = useFormatMessage();
  const history = useHistory();

  useEffect(() => {
    if (pageStageEnum !== StageEnum.RequestSMSToken && pageStageEnum !== StageEnum.RequestEmail) {
      props.closeModal(pageStageEnum, formData);
    }
    if (pageStageEnum === StageEnum.RequestEmail) history.replace(`/login-token`);
  }, [pageStageEnum, formData, props, history]);

  useEffect(() => {
    if (emailToken) {
      generateSMSTokenValidation(emailToken).then((ret: TResponse<TSMSTokenValidation>) => {
        if (ret.data) setMobilePhone(ret.data.mobileFone);
      });
    }
    document.body.classList.remove("backdrop");
  }, [emailToken]);

  useEffect(() => {
    setEmailToken(props.initialEmailToken);
  }, [props.initialEmailToken]);

  const requestEmailToken = formData => {
    generateEmailTokenValidation({
      email: formData.email,
      tenantCode: "TEST",
      urlCallback: `${appUrl}login-token?token=[token]`,
    }).then(ret => {
      if (ret.success) {
        setPageStageEnum(StageEnum.EmailSent);
        setFormData(formData.email);
      } else {
        setPageStageEnum(StageEnum.ErrorSendEmail);
        setFormData(formData.email);
      }
    });
  };

  const sendSMS = formData => {
    generateSMSTokenValidation(formData.emailToken).then(ret => {
      if (ret.data) setMobilePhone(ret.data.mobileFone);
      else setPageStageEnum(StageEnum.ErrorSendSMS);
    });
  };

  const validateSMS = formData => {
    const data = {
      data: {
        emailToken: emailToken,
        mobileFoneToken: formData.mobilePhoneToken,
      },
    };
    validateSMSToken(data);
  };

  const renderSwitch = () => {
    switch (pageStageEnum) {
      case StageEnum.RequestSMSToken:
        return (
          <div id="validateSMSModal" className="modal">
            <Flex flexDirection="column" mb={20}>
              <Form key={2} onSubmit={validateSMS} schema={LoginTokenSMSSchema}>
                <p>You will receive an SMS on the number</p>
                <p>
                  <strong>{mobilePhone}</strong>
                </p>
                <Flex width="50%">
                  <Input id="id" name="id" hidden />
                  <Input
                    id="mobilePhoneToken"
                    name="mobilePhoneToken"
                    label={formatMessage(
                      "app.app.smsToken",
                      "Please enter the SMS code received here"
                    )}
                  />
                </Flex>
                <Flex mt={20} width="100%" justifyContent="flex-start" pr="15px">
                  <Button
                    id="ModalBtn_cancel"
                    textButton
                    mr="10px"
                    onClick={() => setPageStageEnum(StageEnum.RequestEmail)}
                  >
                    {formatMessage("app.cancel", "Cancel")}
                  </Button>
                  <Button id="ModalBtn_save" type="submit">
                    {formatMessage("app.validatemobilephone", "Validate mobile phone")}
                  </Button>
                </Flex>
                <br />
                <p>Didn't receive a text message?</p>
                <Button
                  id="ModalBtn_cancel"
                  textButton
                  mr="10px"
                  onClick={() => {
                    sendSMS({ emailToken: emailToken });
                  }}
                >
                  {formatMessage("app.sendSMSagain", "Send SMS code again")}
                </Button>
                <br />
                <p>
                  <a href="#">I HAVE CHANGED MY MOBILE NUMBER</a>
                </p>
                <br />
              </Form>
            </Flex>
          </div>
        );
      case StageEnum.RequestEmail:
        return (
          <div id="requestEmailTokenModal" className="modal">
            <Flex flexDirection="column" mb={20}>
              <Form key={1} onSubmit={requestEmailToken} schema={LoginTokenEmailSchema}>
                <Flex width="50%">
                  <Input id="id" name="id" hidden />
                  <Input
                    id="email"
                    name="email"
                    label={formatMessage(
                      "app.pleaseloginwithyouremail",
                      "Please login with your e-mail"
                    )}
                  />
                </Flex>
                <Flex mt={20} width="100%" justifyContent="flex-start" pr="15px">
                  <Button id="ModalBtn_save" type="submit">
                    {formatMessage("app.validateemail", "Validate Email")}
                  </Button>
                </Flex>
              </Form>
            </Flex>
          </div>
        );
      default:
        return <div>Error</div>;
    }
  };

  return renderSwitch();
};

export default LoginTokenModal;

import * as constants from "../constants";
import { TDispatch } from "../redux/types";

import http from "@api/http";
import apiCaller from "@api/apiHandler";
import requestWraper from "@api/requestWraper";
import { deserialize } from "@api/jsonApiParser";
import { TResponse } from "@api/types";

export const fetchLanguages = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/Language`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(lang => deserialize(lang));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchLayers = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/Layer`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(info => deserialize(info));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchModules = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/Module`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(info => deserialize(info));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchTranslationTypes = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/TranslationType`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(info => deserialize(info));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchBaseTextTranslates = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/BaseTextTranslate`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(info => deserialize(info));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchApplications = () => {
  return async (dispatch: TDispatch): Promise<Record<string, unknown>[]> => {
    dispatch({ type: constants.LOADING, payload: true });

    return await http.get("Enumerator/Applications").then((response: TResponse) => {
      dispatch({ type: constants.LOADING, payload: false });
      return (response.data as Record<string, unknown>[]).map(apps => deserialize(apps));
    });
  };
};

export const fetchDatabaseTypes = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/DatabaseType`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(apps => deserialize(apps));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchWebServiceBindingTypes = () => {
  return async (dispatch: TDispatch): Promise<Record<string, unknown>[]> => {
    dispatch({ type: constants.LOADING, payload: true });

    return await http.get("Enumerator/WebServiceBindingType").then((response: TResponse) => {
      dispatch({ type: constants.LOADING, payload: false });
      return (response.data as Record<string, unknown>[]).map(webservices =>
        deserialize(webservices)
      );
    });
  };
};

export const fetchGenders = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/Sex`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(apps => deserialize(apps));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchAuthenticationTypes = () => {
  return async (dispatch: TDispatch): Promise<Record<string, unknown>[]> => {
    dispatch({ type: constants.LOADING, payload: true });

    return await http.get("Enumerator/AuthenticationType").then((response: TResponse) => {
      dispatch({ type: constants.LOADING, payload: false });
      return (response.data as Record<string, unknown>[]).map(authenticationsTypes =>
        deserialize(authenticationsTypes)
      );
    });
  };
};

export const fetchObjectTypes = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/ObjectType`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(apps => deserialize(apps));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchTenantMappingTypes = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/TenantMappingType`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(apps => deserialize(apps));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchTenantSettingTypes = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/TenantSettingsType`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(apps => deserialize(apps));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

export const fetchFolderTypes = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/FolderType`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(apps => deserialize(apps));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });

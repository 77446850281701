import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Drawer from "@components/Drawer";
import { Text } from "@components/Text";
import { OldInput } from "@components/OldInput";
import ExpandableRow from "@components/ExpandableRow";
import { ListContainer } from "@components/ListContainer";
import { Column } from "@components/ExpandableRow/styles";
import { ScrollableList } from "@components/ScrollableList";
import { Button, EButtonType } from "@components/OldButton";

import { useFormatMessage } from "@hooks/formatMessage";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";

import "../../../styles/index.scss";
import { TDispatch, TRootState } from "../../../redux/types";
import { setSMSLogsPageNo, fetchSMSLogList, incrementSMSLogPageNo } from "./ducks";

const SMSLogs: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [smsLogFilter, setSmsLogFilter] = useState({
    dynamicFilter: "",
    GenericText: null,
    Username: null,
    FirstName: null,
    LastName: null,
  });

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { smsLogsPageNo, moreSMSLogs, smsLogs } = useSelector(
    ({ SMSLogReducer: { smsLogsPageNo, moreSMSLogs, smsLogs } }: TRootState) => ({
      smsLogsPageNo,
      moreSMSLogs,
      smsLogs,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchSMSLogList()).then(() => dispatch(incrementSMSLogPageNo()));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreSMSLogs) {
      dispatch(fetchSMSLogList(smsLogsPageNo)).then(() => dispatch(incrementSMSLogPageNo()));
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterSMSLogs = () => {
    dispatch(setSMSLogsPageNo(1));
    dispatch(fetchSMSLogList(1, 10, smsLogFilter));
  };

  const clearFilters = () => {
    setSmsLogFilter(prev => ({
      ...prev,
      dynamicFilter: "",
    }));

    dispatch(setSMSLogsPageNo(1));
    dispatch(fetchSMSLogList());
  };

  const renderSMSLogsSideBar = () => {
    return (
      <Drawer id="filterArea" opened={filtersOpened}>
        <div className="paddingContainer">
          <p className="colorGrey mb-1">Dynamic Filter</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="dynamicSearch"
              name="dynamicSearch"
              key="dynamicSearch"
              inputWrapperClass="text-input filter-input"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={smsLogFilter.dynamicFilter}
              onChange={(name, value) =>
                setSmsLogFilter(prev => ({
                  ...prev,
                  dynamicFilter: value,
                }))
              }
              validationMessageConfig={null}
              type="text"
              filtering
            />
          </div>

          <div className="breaker mb-5" />

          <div className="modal-buttons">
            <Button
              dataCyName="buttonClearSearch"
              className="modal-sub-button primary"
              onClick={e => {
                e.stopPropagation();
                clearFilters();
              }}
              buttonType={EButtonType.ButtonText}
            >
              <FormattedMessage id="app.clear" defaultMessage="Clear" />
            </Button>
            <Button
              dataCyName="buttonSearch"
              className="modal-main-button primary"
              onClick={e => {
                e.stopPropagation();
                filterSMSLogs();
              }}
              buttonType={EButtonType.Button}
            >
              <FormattedMessage id="app.search" defaultMessage="Search" />
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <ListContainer>
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <ScrollableList
          items={smsLogs}
          renderItem={(item, index) => (
            <ExpandableRow
              data-cy="smsLog"
              key={"smsLog" + index}
              id={`smsLog${index}`}
              index={index}
              translucent={false}
              expandable={true}
              top={
                <>
                  <Column size={1}>
                    <Text light={false} fontSize="16px" data-cy="smsStatusName">
                      {item.smsStatusName}
                    </Text>
                  </Column>
                  <Column size={3}>
                    <Text light={false} fontSize="16px" data-cy="timestamp">
                      {item.timestamp}
                    </Text>
                  </Column>
                  <Column size={3}>
                    <Text light={false} fontSize="16px" data-cy="recipient">
                      {item.recipient}
                    </Text>
                  </Column>
                  <Column size={3}>
                    <Text light={false} fontSize="16px" data-cy="mobileNumber">
                      {item.mobileNumber}
                    </Text>
                  </Column>
                </>
              }
              more={
                <>
                  <Flex width="100%" mb="20px">
                    <Flex flexDirection="column" flex={1} mr="20px">
                      <Text mb="20px">{formatMessage("app.message", "Message")}</Text>
                      <Text light={false} fontSize="16px">
                        {item.message}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" flex={1}>
                      <Text mb="20px">{formatMessage("app.text", "Text")}</Text>
                      <Text light={false} fontSize="16px">
                        {item.text}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              }
            />
          )}
          renderHeader={
            <>
              <Column size={1}>
                <Text>{formatMessage("app.smsStatusName", "Status")}</Text>
              </Column>
              <Column size={3}>
                <Text>{formatMessage("app.timestamp", "Date")}</Text>
              </Column>
              <Column size={3}>
                <Text>{formatMessage("app.recipient", "Recipient")}</Text>
              </Column>
              <Column size={3}>
                <Text>{formatMessage("app.mobileNumber", "Mobile Number")}</Text>
              </Column>
              <Column icon>
                <div
                  id="toggleFilterArea"
                  className="icon-filter"
                  tabIndex={0}
                  onClick={e => {
                    e.stopPropagation();
                    changeFilterOpened();
                  }}
                >
                  <IconFilter />
                </div>
              </Column>
            </>
          }
          onLoadMore={handleScroll}
        />
        {renderSMSLogsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default SMSLogs;
